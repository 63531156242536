import React from "react";

export function tagsFormatter(tags: string[], row: any, rowIndex: number, formatExtraData: any): JSX.Element {
    return (
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
            {tags.filter(Boolean).map((tag, i) => (
                <div key={i} style={{
                    fontSize: "9px",
                    padding: "1px 6px",
                    margin: "2px 3px",
                    borderRadius: "3px",
                    color: "black",
                    backgroundColor: "#ee14472e",
                    whiteSpace: "nowrap"}}>
                    {tag}
                </div>
            ))}
        </div>
    );
}
