import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {WebApiClient} from "../../../common/webApiClient";
import {Subscriber} from "../../../models/Subscriber";
import styles from './SubscriberModal.module.css';
import {Button} from "reactstrap";
import {TooltipElement} from "../../LayoutComponents/Tooltip/TootipElement";

type AccountModalProps = {
    subscriber: Subscriber | undefined,
    closeModal: () => void,
};

export const SubscriberModal: React.FC<AccountModalProps> = ({subscriber, closeModal}) => {
    const apiClient = WebApiClient();
    const [inviteSent, setInviteSent] = useState(false);
    const [error, setError] = useState<null | string>(null);

    const closeModalButton = () => (
        <FontAwesomeIcon className={styles.closeButton} icon="times" onClick={closeModal} />
    );

    return (
        <div className={styles.container} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div style={{marginBottom: "20px"}}>Name: {subscriber?.name}</div>
            <div style={{color: "red"}}>{error}</div>
            {subscriber?.hasAccount ? <div style={{color: "green"}}>Has Account</div>: inviteSent ? <div style={{color: "green"}}>{"Invited"}</div> :
                <span><Button style={{marginRight: "4px"}} onClick={() => sendInvite(subscriber?.email)}>Invite</Button><TooltipElement tooltip={"View invites in the invites tab"}/></span>}
            {closeModalButton()}
        </div>
    );

    async function sendInvite(email: string | undefined) {
        try {
            const data = {email: email}
            const url = 'api/invite/';
            await apiClient.post(url, data);
            setInviteSent(true);
        } catch(e: any) {
            setError(e.message);
            console.log(e.message);
        }
    }
}
