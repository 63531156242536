import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import logo from '../../../images/logcast_logo.png';
import oshiLogo from '../../../images/oshi_logo.png';
import useWindowDimensions from '../useWindowDimensions';
import SideDrawerItem from './SideDrawerItem';

import { useLocalStorage } from "../../../common/useLocalStorage";
import './SideDrawer.css';

type SideDrawerProps = {
  drawerClickHandler: () => void,
  show: boolean,
  isOshi: boolean,
}

const SideDrawer: React.FC<SideDrawerProps> = ({drawerClickHandler, show, isOshi}) => {
  const { width } = useWindowDimensions();
  const [permissions, setPermissions] = useLocalStorage("permissions", [] as string[]);

  let drawerClasses = 'side-drawer';
  if (show) drawerClasses = 'side-drawer open';

  const logoStyle: CSSProperties = {
    maxHeight: "45px", 
    marginTop: '25px', 
    marginLeft: "25px",
  };

  const closeSideDrawerIconStyle: CSSProperties = {
    position: "absolute", 
    top: "10px", 
    right: "10px", 
    fontSize: "20px", 
    color: "grey",
    cursor: "pointer",
  };

  return (
    <div className="sidedrawer-container">
      <nav className={drawerClasses}>
        <img src={isOshi ? oshiLogo : logo} style={logoStyle} alt={"Logcast logo"}/>
        {width < 768 && <FontAwesomeIcon style={closeSideDrawerIconStyle} icon="times" onClick={drawerClickHandler}/>}
        <ul>
          <NavItem>
            <NavLink to="/dashboard" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Dashboard" icon="tachometer-alt" />
            </NavLink>
          </NavItem>
          <hr style={{borderColor: "white", width: "100%"}}/>
          <NavItem>
            <NavLink to="/accounts" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Accounts" icon="user-friends" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/logs" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name={isOshi ? "Audio" : "Logs"} icon="circle" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/notifications" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Notifications" icon="clock" />
            </NavLink>
          </NavItem>
					{isOshi ?  <></> : <NavItem>
            <NavLink to="/projects" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Circles" icon="project-diagram" />
            </NavLink>
          </NavItem>}
          
          <NavItem>
            <NavLink to="/reported-logs" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Reports" icon="flag" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/issues" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Issues" icon="exclamation" />
            </NavLink>
          </NavItem>
					{isOshi ? <></> : <><NavItem>
            <NavLink to="/subscribers" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Subscribers" icon="envelope" />
            </NavLink>
          </NavItem>
          <hr style={{borderColor: "white", width: "100%"}}/>
          <NavItem>
            <NavLink to="/topics" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Topics" icon="comments" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/faq" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="FAQ" icon="question" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/legal" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Legal Documents" icon="file-alt" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/redirects" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Redirects" icon="directions" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/welcomemessage" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem name="Welcome Message" icon="comment-alt" />
            </NavLink>
          </NavItem>
          <hr style={{borderColor: "white", width: "100%"}}/>
					</>
					}
          <NavItem>
            <NavLink to="/apilog" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem icon="clipboard-list" name="Api Log" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/healthcheck" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem icon="notes-medical" name="Health Check" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/tracelog" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem icon="weight" name="Trace Log" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/analytics" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem icon="weight" name="Analytics" />
            </NavLink>
          </NavItem>
          <hr style={{borderColor: "white", width: "100%"}}/>
          <NavItem>
            <NavLink to="/profile" onClick={drawerClickHandler} activeClassName="active">
              <SideDrawerItem icon="user" name="Profile" />
            </NavLink>
          </NavItem>
          {
            permissions.includes("Elevated") &&
            <NavItem>
              <NavLink to="/admin-accounts" onClick={drawerClickHandler} activeClassName="active">
                <SideDrawerItem icon="user-friends" name="Other Admin Accounts" />
              </NavLink>
            </NavItem>
          }
        </ul>
      </nav>
    </div>
)};

export default SideDrawer;