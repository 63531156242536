import {AccountProfile} from "../../../models/AccountProfile";
import styles from "./AccountView.module.css";
import {Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

interface AccountViewProps {
    account: AccountProfile | undefined;
}

export const AccountView: React.FC<AccountViewProps> = (props) => {
    const account = props.account;


    return (
        <div className={styles.authorWrapper}>
            <div className={styles.avatar}>
                {account?.profileImage ? <Image src={account.profileImage.imageUrl} style={{width: "64px"}}/>: <FontAwesomeIcon icon={"user-circle"}/>}
            </div>
            <div className={styles.username_status}>
                <div className={styles.username}>
                    {account?.userName} {account?.name ? "(" + account.name + ")" : null}
                </div>
                <div className={styles.status}>
                    {account?.accountStatus}
                </div>
            </div>
            <div className={styles.bio}>
                {account?.biography}
            </div>
        </div>
    )
}