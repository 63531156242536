import React, {useEffect, useState} from "react";
import styles from './WelcomeMessageList.module.css';
import {Button, Form, FormGroup} from "react-bootstrap";
import {WelcomeMessageModel} from "../../../models/WelcomeMessageModel";
import {WelcomeMessage} from "./WelcomeMessage";
import {WebApiClient} from "../../../common/webApiClient";
import {AccountView} from "../../UserContentPages/Accounts/AccountView";
import {AccountProfile} from "../../../models/AccountProfile";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const WelcomeMessageList: React.FC = () => {
    const [error, setError] = useState("");
    const [note, setNote] = useState("");
    const [file, setFile] = useState(null as File | null);
    const [uploadStatus, setUploadStatus] = useState("");
    const [previousVersions, setPreviousVersions] = useState([] as WelcomeMessageModel[]);

    const [account, setAccount] = useState(undefined as AccountProfile | undefined)
    const [profileImage, setProfileImage] = useState(null as File | null);

    const fetchPreviousMessages = () => {
        const apiClient = WebApiClient();

        apiClient.get<WelcomeMessageModel[]>('/api/resources/welcome-messages')
            .then(response => {
                setPreviousVersions(response);
            })
            .catch(e => {
                setError(e.message);
            })
    }

    const fetchLogcastAccount = () => {
        const apiClient = WebApiClient();

        apiClient.get<AccountProfile>('/api/accounts/system/logcast-team')
            .then(response => {
                setAccount(response);
            }).catch(e => {
            setError(e.message);
        })
    }

    useEffect(() => {
        fetchPreviousMessages();
    }, []);

    useEffect(() => {
        fetchLogcastAccount();
    }, []);

    const uploadFile = () => {
        const apiClient = WebApiClient();
        if (file) {
            apiClient.post<{id: string}>('/api/resources/welcome-messages', {note: note})
                .then(response => {
                    apiClient.postFile('/api/resources/welcome-messages/' + response.id + '/file', file)
                        .then(response => {
                            setUploadStatus('success');
                            fetchPreviousMessages();
                        }).catch(e => {
                        setError(e.message);
                        setUploadStatus('failed');
                    })
                }).catch(e => {
                setError(e.message);
                setUploadStatus('failed');
            })
        }
    }

    const uploadProfileImage = () => {
        const apiClient = WebApiClient();
        if (profileImage && account) {
            apiClient.postFile('/api/accounts/system/' + account.accountId + '/profile-image', profileImage)
                .then(response => {
                    fetchLogcastAccount();
                }).catch(e => {
                setError(e.message);
            })
        }
    }

    const uploadedStatus = (status: string) => {
        switch (status) {
            case "failed": return <div className={styles.failed}>Failed</div>
            case "success": return <div className={styles.success}>Success</div>
            default: return <div/>
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>Welcome Message</div>
            <div className={styles.error}>{error}</div>
            <div className={styles.logcast_account_container}>
                <AccountView account={account} />
                <Form className={styles.logcast_account_form} onSubmit={(e) => {e.preventDefault(); uploadProfileImage()}}>
                    <FormGroup>
                        <div className={styles.new_version_file_wrapper}>
                            <div className={styles.new_version_upload}>Set a new profile image</div>
                            <Form.File className={styles.new_version_file} id={"file"} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {if(e?.target?.files != null) setProfileImage(e.target.files[0])}} />
                            <Button variant={"primary"} type={"submit"}>Upload image</Button>
                        </div>
                    </FormGroup>
                </Form>
            </div>
            <div className={styles.new_version_container}>
                <div className={styles.new_version_title}>New version</div>
                <div className={styles.new_version_chooser_wrapper}>
                    <Form className={styles.new_version_form} onSubmit={(e) => {e.preventDefault(); uploadFile()}}>
                        <div className={styles.new_version_header}>
                            <div className={styles.new_version_note}>Note</div>
                            <div className={styles.new_version_upload}>Uploaded</div>
                            <div className={styles.new_version_file}>Choose File</div>
                        </div>
                        <FormGroup>
                            <div className={styles.new_version_file_wrapper}>
                                <Form.Control type={"text"} className={styles.new_version_note} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNote(e.target.value)} />
                                <div className={styles.new_version_upload}>{uploadedStatus(uploadStatus)}</div>
                                <Form.File className={styles.new_version_file} id={"file"} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {if(e?.target?.files != null) setFile(e.target.files[0])}} />
                            </div>
                        </FormGroup>
                        <Button variant={"primary"} type={"submit"}>Submit</Button>
                    </Form>
                </div>
            </div>
            <div className={styles.previous_versions_container}>
                <div className={styles.previous_versions_row}>
                    <div className={styles.previous_versions_header}>Other versions</div>
                    <div className={styles.refresh_icon} onClick={() => fetchPreviousMessages()}><FontAwesomeIcon icon={"sync"} /></div>
                </div>
                {previousVersions.map(p => {
                    return (
                        <WelcomeMessage message={p} />
                    )
                })}
            </div>
        </div>
    )
}