
import React from "react";


export const HealthCheckTable: React.FC = () => {

    return (
        <div style={{width: "100%", height: "100%"}}>
            <iframe style={{width: "100%", height: "90%"}} src={"/healthchecks-ui"} />
        </div>
    )
}