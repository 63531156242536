import React from 'react';
import {DragSourceMonitor, useDrag} from 'react-dnd';

interface DraggableCardProps {
  id: string,
  type: string,
  onDrop: (id: string, result: string) => Promise<void>
  onClick: (id: string) => void
}

export const DraggableCard: React.FC<DraggableCardProps> = ({children, id, type, onDrop, onClick }) => {
  const [{ isDragging }, dragRef] = useDrag({
    item: { id: id, type: type },
    end: (item: { id: string, type: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(id, dropResult.status);
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    })
  })

  return (
    <div 
      className="board-list__item" 
      ref={dragRef} 
      style={{ opacity: isDragging ? 0 : 1 }}
      onClick={() => onClick(id)}>
      {children}
    </div>
  )
}
