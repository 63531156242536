import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, FormControl, FormGroup, FormLabel, Row, Dropdown} from "react-bootstrap";
import {WebApiClient} from "../../../common/webApiClient";
import './LoginForm.css';
import Cookie from "js-cookie";
import {AuthenticationResponse} from "../../../models/AuthenticationResponse";
import {useLocalStorage} from "../../../common/useLocalStorage";
import {getRolesFromJwt} from "../../../common/getRolesFromJwt";

export const LoginForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [appId, setAppId] = useState("Logcast");
    const [error, setError] = useState("");
    let history = useHistory();
    const [permissions, setPermissions] = useLocalStorage("permissions",  [] as string[]);

    function validateForm() {
        return username.length > 0 && password.length > 0;
    }

    const handleLogin = async (event: any) => {
        event.preventDefault();
        const apiClient = WebApiClient();
        try {
            const tokens = await apiClient.post<AuthenticationResponse>("api/authentication", {
                "username": username,
                "password": password
            },{"x-app-name": appId});
            setPermissions(getRolesFromJwt(tokens.accessToken));
            Cookie.set("signed_in", "true");
            history.push("/dashboard");
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        }
    };

    return (
        <Row className="Login justify-content-center align-items-center">
            <form onSubmit={handleLogin}>
                <FormGroup controlId="appId">
                    <FormLabel>App</FormLabel>
                    <select
												className="form-control"
                        autoFocus
                        value={appId}
                        onChange={e => setAppId(e.target.value)}
                    >
											<option value="Logcast">Logcast</option>
											<option value="Oshi">Oshi</option>
										</select>
                </FormGroup>
                <FormGroup controlId="username">
                    <FormLabel>Username</FormLabel>
                    <FormControl
                        autoFocus
                        type="text"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="password">
                    <FormLabel>Password</FormLabel>
                    <FormControl
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                    />
                </FormGroup>
                <Button variant="danger" block disabled={!validateForm()} type="submit">
                    Login
                </Button>
                {error && <div style={{color: "red"}}>{error}</div>}
                <a href={'reset-password'}>Forgot password?</a>
            </form>
        </Row>
    );
}