import React, {useState} from "react";
import {NavMenu} from './NavMenu';
import SideDrawer from './SideDrawer/SideDrawer';
import {useHistory} from "react-router-dom";
import {WebApiClient} from "../../common/webApiClient";
import useWindowDimensions from './useWindowDimensions';
import Backdrop from './Backdrop/Backdrop';
import '../../index.css';
import Cookie from "js-cookie";

export const Layout: React.FC = (props: any) => {
    const { width } = useWindowDimensions();
    const [isSideDrawerShowing, toggleSideDrawer] = useState(false);
    
    let history = useHistory();
		const isOshi = Cookie.get('app_name') !== 'Logcast'
    const handleLogout = async () => {
        const apiClient = WebApiClient();
        await apiClient.get("api/authentication/logout");
        history.push("/");
    };

    return (
        <div className="main">
            <NavMenu drawerClickHandler={drawerClickHandler} logoutHandler={handleLogout} />
            {isSideDrawerShowing && width < 768 && <Backdrop backdropClickHandler={backdropClickHandler}/>}
            <SideDrawer 
                drawerClickHandler={drawerClickHandler} 
                show={width < 768 ? isSideDrawerShowing : true}
								isOshi={isOshi}
                 />
            <div className="content-wrapper">
                <div className="content-center" style={{maxWidth: "none"}}>
                    { props.children }
                </div>
            </div>
        </div>
    );

    function drawerClickHandler() {
        if (width < 768) toggleSideDrawer(!isSideDrawerShowing);
    }

    function backdropClickHandler() {
        toggleSideDrawer(false);
    }
};