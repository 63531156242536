import {Button, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {WebApiClient} from "../../../common/webApiClient";
import {useHistory} from "react-router-dom";


export const ExternalChangePasswordForm: React.FC<{accountId: string, token: string}> = (params) => {
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [error, setError] = useState("");
    const [response, setResponse] = useState("");
    const history = useHistory();
    const [mismatchError, setMismatchError] = useState("");

    useEffect(() => {
        if (!password2) return;
        if (password1 !== password2) {
            setMismatchError("Password do not match")
        } else {
            setMismatchError("");
        }
    }, [password2, password1])

    function validateForm() {
        if (password1.length <= 0 || password2.length <= 0) return false;
        if (password1 !== password2) return false;

        return true;
    }

    const handleChangePassword = async (event: any) => {
        event.preventDefault();
        const apiClient = WebApiClient();
        try {
            await apiClient.post("api/system-accounts/password-reset", {
                "accountId": params.accountId,
                "newPassword": password1,
                "token": params.token,
            });
            setResponse("Password updated!");
            setTimeout(() => {
                history.push("/login");
            }, 2500);
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        }
    };

    return (
        <Row className="Login justify-content-center align-items-center">
            <form onSubmit={handleChangePassword}>
                <FormGroup controlId="password1">
                    <FormLabel>Password</FormLabel>
                    <FormControl
                        autoFocus
                        type="password"
                        value={password1}
                        onChange={e => setPassword1(e.target.value)}
                    />
                </FormGroup>
                {mismatchError && <div style={{color: "red"}}>{mismatchError}</div>}
                <FormGroup controlId="password2">
                    <FormLabel>Re-type password</FormLabel>
                    <FormControl
                        autoFocus
                        type="password"
                        value={password2}
                        onChange={e => setPassword2(e.target.value)}
                    />
                </FormGroup>
                <Button variant="danger" block disabled={!validateForm()} type="submit">
                    Reset Password
                </Button>
                {error && <div style={{color: "red"}}>{error}</div>}
                {response && <div style={{color: "green"}}>{response}</div>}
            </form>
        </Row>
    );
}