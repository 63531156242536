import React, {useState} from "react";
import {Button, Form, FormGroup} from "react-bootstrap";
import styles from "../../AdminContentPages/WelcomeMessage/WelcomeMessageList.module.css";
import {AccountStatus, AdminAccountType} from "../../../common/constants";
import {WebApiClient} from "../../../common/webApiClient";


export const AddAccountPanel: React.FC<{populateAccounts: () => void}> = (props) => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [type, setType] = useState("");
    const [error, setError] = useState("");

    const submitNewAccount = () => {
        const client = WebApiClient();
        client.post('/api/system-accounts', {Username: username, Email: email})
            .then(response => {
                props.populateAccounts();
                setError("");
            }).catch(e => {
                setError(e.message);
        })
    }

    return (
        <div>
            <h5>Create a new account</h5>
            <Form className={styles.new_account_form} inline onSubmit={(e) => {e.preventDefault(); submitNewAccount()}}>
                <FormGroup>
                    <Form.Label htmlFor={"inlineFormInputName2"} srOnly>Name</Form.Label>
                    <Form.Control type={"text"} className={styles.new_version_note} placeholder={"Username"} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)} />
                    <Form.Label htmlFor={"inlineFormInputName2"} srOnly>Email</Form.Label>
                    <Form.Control type={"text"} className={styles.new_version_note} placeholder={"Email"} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
                    <Form.Control as={"select"} value={type} onChange={(e) => setType(e.target.value)}>
                        {Object.values(AdminAccountType).map((type, i) => (
                            <option key={i} value={type} disabled={type === AdminAccountType.Elevated}>{type}</option>
                        ))}
                    </Form.Control>
                    <Button variant={"primary"} type={"submit"}>Add Account</Button>
                </FormGroup>
            </Form>
            {error && <div style={{color: "red"}}>{error}</div>}
        </div>
    )
}