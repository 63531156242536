import styles from "./LogModal.module.css";
import React, {useEffect, useState} from "react";
import {Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ClickableUserName} from "../../LayoutComponents/ClickableLinks/ClickableInternalLinks";
import {Log} from "../../../models/Log";
import {WebApiClient} from "../../../common/webApiClient";
import {LogStatus} from "../../../common/constants";
import ReactPlayer from "react-player";
import {AccountProfileFull} from "../../../models/AccountProfile";
import {StreamInfo} from "../../../models/StreamInfo";

interface LogViewProps {
    log: Log | undefined
}

export const LogView: React.FC<LogViewProps> = (props) => {
    const apiClient = WebApiClient();
    const [error, setError] = useState("");
    const [stream, setStream] = useState(undefined as StreamInfo | undefined);
    const [account, setAccount] = useState(undefined as AccountProfileFull | undefined);
    const log = props.log;

    useEffect(() => {
        if (!log?.recordingFile) {
            apiClient.get<StreamInfo>('/api/streams/' + log?.recordingFileId)
                .then(response => {
                    setStream(response)
                })
                .catch(e => {
                    setError(e.message);
                    console.log(e.message);
                });
        } else {
            setStream(log.recordingFile.streamLocation);
        }

    }, [log?.recordingFile]);

    useEffect(() => {
        if (!log?.accountId) return;

        apiClient.get<AccountProfileFull>('api/accounts/' + log.accountId)
            .then(reponse => {
                setAccount(reponse);
            })
            .catch(e => {
                setError(e.message);
                console.log(e.message);
            })
    }, [log?.accountId]);

    const author = () => (
        <div className={styles.authorWrapper}>
            <div className={styles.avatar}>
                {account?.profileImage ? <Image src={account?.profileImage.imageUrl} style={{width: "64px"}}/>: <FontAwesomeIcon icon={"user-circle"}/>}
            </div>
            <div className={styles.username}>
                {ClickableUserName(account?.userName)}
            </div>
        </div>
    );

    const interactions = () => (
        <div className={styles.postTop}>
            <div className={styles.interactionsContainer}>
                <div className={styles.interactionIcon}>
                    <FontAwesomeIcon icon="heart" />
                </div>
                {log?.likes.length}
                <div className={styles.interactionIcon}>
                    <FontAwesomeIcon icon="share" />
                </div>
                {log?.sharedBy.length}
                <div className={styles.interactionIcon}>
                    <FontAwesomeIcon icon={"headphones"}/>
                </div>
                {log?.recordingFile?.totalNumberOfStreams}
            </div>
            {error && <div style={{color: "red"}}>error</div>}
        </div>
    );

    const location = () => (
        <div className={styles.postLocation}>
            {log?.location && log?.location?.country}
        </div>
    );

    const postedDate = () => (
        <div className={styles.postTime}>
            {log && new Date(log.postedDate).toLocaleString()}
        </div>
    );

    const tags = () => (
        <div className={styles.postTags}>
            {log?.tags && log?.tags.map(t => `#${t}`).join(' ')}
        </div>
    );

    const status = () => (
        <div className={styles.removed}>
            {log?.logStatus === LogStatus.Removed ? "Removed" : ""}
        </div>
    )

    const logId = () => (
        <div>
            Id: {log?.logId}
        </div>
    )

    const recordingPlayer = () => {
        if (!stream) return null;
        return (
            <>
                <div className={styles.postTitle}>{log?.title}</div>
                <Image src={log?.image?.imageUrl} style={{width: "480px"}}/>
                <ReactPlayer height="30px" width="480px" url={stream.dashUrl} config={{file: {forceDASH: true,}}} playsinline controls/>
                <div className={styles.description}>{log?.description}</div>
            </>
        )
    };

    return (
        <div>
            {author()}
            <div className={styles.postContainer}>
                {interactions()}
                {recordingPlayer()}
                <div className={styles.postBottomContainer}>
                    <div className={styles.postLocationTime}>
                        {location()}
                        {postedDate()}
                    </div>
                    {tags()}
                    {status()}
                    {logId()}
                </div>
            </div>
        </div>
    )
}