import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faCircle,
	faClipboardList,
	faComment,
	faCommentAlt,
	faComments,
	faDirections,
	faEnvelope,
	faExclamation,
	faFileAlt,
	faFileAudio,
	faFlag,
	faHeadphones,
	faHeadphonesAlt,
	faHeart,
	faNotesMedical,
	faPauseCircle,
	faPlayCircle,
	faPlus,
	faProjectDiagram,
	faQuestion,
	faQuestionCircle,
	faShare,
	faSignOutAlt,
	faSync,
	faTachometerAlt,
	faTimes,
	faUser,
	faUserCircle,
	faUserFriends,
	faVolumeUp,
	faWeight,
	faClock
} from '@fortawesome/free-solid-svg-icons';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Dashboard } from './components/AnalyticsPages/Dashboard/Dashboard';
import { Landing } from './components/LayoutComponents/Landing';
import { LoginPage } from './components/LayoutComponents/Login/LoginPage';
import { ProtectedRoute, ProtectedRouteProps } from './components/LayoutComponents/ProtectedRoute';
import { IssueBoard } from './components/UserContentPages/Issues/IssueBoard';
import { ReportsBoard } from './components/UserContentPages/Reports/ReportsBoard';

import { LegalDocumentsEditor } from "./components/AdminContentPages/LegalDocuments/LegalDocumentsEditor";
import { RedirectsTable } from "./components/AdminContentPages/Redirects/RedirectsTable";
import { TopicsTable } from "./components/AdminContentPages/Topics/TopicsTable";
import { WelcomeMessageList } from "./components/AdminContentPages/WelcomeMessage/WelcomeMessageList";
import { AnalyticsTable } from "./components/AnalyticsPages/Analytics/AnalyticsTable";
import { EndpointLog } from "./components/AnalyticsPages/ApiLog/EndpointLog";
import { HealthCheckTable } from "./components/AnalyticsPages/HealthChecks/HealthCheckTable";
import { TraceLogTable } from "./components/AnalyticsPages/TraceLog/TraceLogTable";
import { ExternalChangePasswordPage } from "./components/LayoutComponents/ExternalChangePassword/ExternalChangePasswordPage";
import { ResetPasswordPage } from "./components/LayoutComponents/ResetPassword/ResetPasswordPage";
import { AdminAccountsTable } from "./components/SystemPages/AdminAccounts/AdminAccountsTable";
import { ProfileView } from "./components/SystemPages/Profile/ProfileView";
import { LogsTable } from "./components/UserContentPages/ALogs/LogsTable";
import { AccountsTable } from "./components/UserContentPages/Accounts/AccountsTable";
import { FAQBoard } from "./components/UserContentPages/FAQ/FAQBoard";
import { NotificationsTable } from './components/UserContentPages/Notifications/NotificationsTable';
import { ProjectsTable } from "./components/UserContentPages/Projects/ProjectsTable";
import { SubscribersTable } from "./components/UserContentPages/Subscribers/SubscribersTable";
import './custom.css';

library.add(
    faTachometerAlt, 
    faFlag, 
    faExclamation, 
    faSignOutAlt, 
    faTimes, 
    faCircle, 
    faHeart, 
    faComment, 
    faShare, 
    faUserCircle, 
    faPlayCircle,
    faPauseCircle,
    faVolumeUp,
    faUserFriends,
    faComments,
    faQuestion,
    faPlus,
    faEnvelope,
    faProjectDiagram,
    faHeadphones,
    faFileAlt,
    faQuestionCircle,
    faDirections,
    faNotesMedical,
    faClipboardList,
    faWeight,
    faFileAudio,
    faHeadphonesAlt,
    faCommentAlt,
    faSync,
    faUser,
		faClock
);

const defaultProtectedRouteProps: ProtectedRouteProps = {
    authenticationPath: '/',
};

export const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path='/' component={Landing} />
                <ProtectedRoute 
                    component={Dashboard}  
                    exact path='/dashboard' 
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    component={AccountsTable}
                    exact path='/accounts'
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    component={TopicsTable}
                    exact path='/topics'
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute 
                    exact path='/logs' 
                    component={LogsTable}
                    {...defaultProtectedRouteProps}/>
								<ProtectedRoute 
										exact path='/notifications' 
										component={NotificationsTable}
										{...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/projects'
                    component={ProjectsTable}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute 
                    exact path='/reported-logs' 
                    component={ReportsBoard}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute 
                    exact path='/issues' 
                    component={IssueBoard}  
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/faq'
                    component={FAQBoard}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/legal'
                    component={LegalDocumentsEditor}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path={'/redirects'}
                    component={RedirectsTable}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/subscribers'
                    component={SubscribersTable}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/apilog'
                    component={EndpointLog}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/healthcheck'
                    component={HealthCheckTable}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/tracelog'
                    component={TraceLogTable}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/analytics'
                    component={AnalyticsTable}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/welcomemessage'
                    component={WelcomeMessageList}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/profile'
                    component={ProfileView}
                    {...defaultProtectedRouteProps}/>
                <ProtectedRoute
                    exact path='/admin-accounts'
                    component={AdminAccountsTable}
                    {...defaultProtectedRouteProps}/>
                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/reset-password' component={ResetPasswordPage} />
                <Route exact path='/password-reset/:accountId' component={ExternalChangePasswordPage} />
            </Switch>
        </Router>
    );
};
