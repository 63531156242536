import React from 'react';
import {useDrop} from 'react-dnd';
import {FAQItem} from "../../../models/FAQItem";
import update from 'immutability-helper'
import {DraggableOrderedCard} from "./DraggableOrderedCard";
import {ItemTypes} from "../../../common/constants";
import './FAQ.css';

interface FeedbackQAListProps {
    faqs: FAQItem[],
    setFAQs: (a: FAQItem[]) => void,
    updateCardPosition: (id: string, to: number) => void,
    openModal: (id: string) => void,
}

export const FAQList: React.FC<FeedbackQAListProps> = ({faqs, setFAQs, updateCardPosition, openModal}) => {
    const renderCard = (faqItem: FAQItem) => {
        return (
            <DraggableOrderedCard
                key={faqItem.id}
        id={faqItem.id}
        question={faqItem.question}
        answer={faqItem.answer}
        moveCard={moveCard}
        findCard={findCard}
        updateCardPosition={updateCardPosition}
        openModal={openModal}
        />
    )
    }

    const findCard = (id: string) => {
        const card = faqs.filter((f) => f.id === id)[0]
        return {
            card,
            index: faqs.indexOf(card),
        }
    }

    const moveCard = (id: string, atIndex: number) => {
        const { card, index } = findCard(id);
        setFAQs(update(faqs, {
                $splice: [
                    [index, 1],
                    [atIndex, 0, card],
                ],
            }),
        )
    }

    const [, drop] = useDrop({accept: ItemTypes.FeedbackQA})

    return (
        <div className={"faq-list"} ref={drop}>
            {faqs.map((faqItem) => renderCard(faqItem))}
            <div className={"draggable-card"} onClick={() => openModal("create")}>
                <div className={"question"} style={{display: "inline-block"}}>Create new question</div>
            </div>
        </div>
    );
};