import React, {useState} from 'react';
import {WebApiClient} from '../../../common/webApiClient';
import {BoardList} from '../../LayoutComponents/BoardList';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DraggableCard} from '../../LayoutComponents/DraggableCard';
import {ItemTypes} from '../../../common/constants';
import Backdrop from '../../LayoutComponents/Backdrop/Backdrop';
import {ReportsModal} from "./ReportsModal";
import {Report} from "../../../models/Report";
import {PagedResponse} from "../../../models/PagedResponse";

export const ReportsBoard: React.FC = () => {
    const apiClient = WebApiClient();
    const [reported, setReported] = useState<tReportData>(initReports);
    const [inReview, setInReview] = useState<tReportData>(initReports);
    const [reReported, setReReported] = useState<tReportData>(initReports);
    const [closed, setClosed] = useState<tReportData>(initReports);
    const [showModal, toggleModal] = useState(false);
    const [selectedReport, selectReport] = useState({} as Report | undefined);

    React.useEffect(() => {
        populateReportedPosts("Reported", setReported);
        populateReportedPosts("InReview", setInReview);
        populateReportedPosts("ReReported", setReReported);
        populateReportedPosts("Closed", setClosed);
    }, []);

    type tReportData = {
        reports: Report[],
        totalOfType: number,
        loading: boolean,
    }
    type tListData = {
        title: string,
        data: tReportData,
    }
    type tListNames = {
        [key: string]: tListData
    };
    const listNames: tListNames = {
        "Reported": {title: "Reported", data: reported},
        "InReview": {title: "In review", data: inReview},
        "ReReported": {title: "Re-reported", data: reReported},
        "Closed": {title: "Closed", data: closed},
    };

    function renderReportedPosts() {
        return (
          <div style={{width: "100%"}}>
            <h4>Reported Logs</h4>
            <div className="board-container">
                {Object.entries(listNames).map(([reportStatus, data]) =>
                    data.data.loading ? <div>Loading...</div> :
                    <BoardList key={reportStatus} type={ItemTypes.Report} title={data.title} status={reportStatus}>
                        {data.data.reports.map(r =>
                            <DraggableCard
                              key={r.reportId}
                              id={r.reportId}
                              type={ItemTypes.Report}
                              onDrop={onDrop}
                              onClick={openModal}>
                                <div className="metafield">type: {r.reportType}</div>
                                <div className="metafield">date: {new Date(r.reportedDate).toLocaleString()}</div>
                                <div className="content">message: {r.message.substr(0, 50)}{r.message.length > 50 ? "..." : ""}</div>
                            </DraggableCard>
                        )}
                    </BoardList>
                )}
            </div>
          </div>
        );
    }

    return (
        <DndProvider backend={HTML5Backend}>
            {renderReportedPosts()}
            {showModal && <Backdrop backdropClickHandler={backdropClickHandler} />}
            {showModal && <ReportsModal report={selectedReport} closeModal={backdropClickHandler}/>}
        </DndProvider>
    );

    function initReports(): tReportData {
        return {reports: [], totalOfType: 0, loading: true}
    }

    async function populateReportedPosts(type: string, setFunc: (data: tReportData) => void) {
      try {
          const query = "?Only" + type + "=true";
        const response = await apiClient.get<PagedResponse<Report[]>>("api/reports" + query);
        const data: tReportData = {reports: response.data, totalOfType: response.totalSize ?? 0, loading: false};
        setFunc(data);
      } catch (e) {
        console.log(e);
      }
    }

    async function onDrop(id: string, newStatus: string): Promise<void> {
      try {
        const data = {reportStatus: newStatus};
        await apiClient.put(`api/reports/${id}/update-report-status`, data);
        const report = findReportById(id);
        if (report)
        populateReportedPosts(report.reportStatus, findSetFuncByStatus(report.reportStatus));
        populateReportedPosts(newStatus, findSetFuncByStatus(newStatus));
      } catch (e) {
        console.log(e);
      }
    }

    async function openModal(reportId: string): Promise<void> {
      const report = findReportById(reportId);
      if (report) {
        selectReport(report);
        toggleModal(true);
      }
    }

    function findSetFuncByStatus(status: string) {
        if (status === "Reported")
            return setReported
        if (status === "InReview")
            return setInReview
        if (status === "ReReported")
            return setReReported
        if (status === "Closed")
            return setClosed
        return () => {}
    }

    function findReportById(reportId: string) {
        const r1 = reported.reports.find(r => r.reportId === reportId);
        if (r1)
            return r1;
        const r2 = inReview.reports.find(r => r.reportId === reportId);
        if (r2)
            return r2;
        const r3 = reReported.reports.find(r => r.reportId === reportId);
        if (r3)
            return r3;
        const r4 = closed.reports.find(r => r.reportId === reportId);
        if (r4)
            return r4;
        return null;
    }

    function backdropClickHandler(): void {
      toggleModal(false);
    }
};