export const ItemTypes = {
  Issue: "issue",
  Report: "reportedPost",
  FeedbackQA: "feedbackQA"
}

export const AccountTypes = {
  Standard: "Standard",
  Creator: "Creator",
  System: "System",
}

export const AccountStatus = {
  Active: "Active",
  Suspended: "Suspended",
  Hidden: "Hidden",
  Closed: "Closed",
  Banned: "Banned",
}

export const LogStatus = {
  Active: "Active",
  Removed: "Removed",
}

export const ProjectStatus = {
  Active: "Active",
  Removed: "Removed",
}

export const SellerStatus = {
  Active: "Active",
  UpdateRequired: "UpdateRequired",
  Inactive: "Inactive",
}

export const LegalDocumentTypes = {
  PrivacyPolicy: "Privacy Policy",
  TermsAndConditions: "Terms and Conditions",
}

export const AdminAccountType = {
  Regular: "Regular",
  Elevated: "Elevated"
}

export const AdminAccountStatus = {
  Active: "Active",
  Removed: "Removed",
}