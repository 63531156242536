import React, {useState} from 'react';
import {WebApiClient} from '../../../common/webApiClient';
import {Issue} from '../../../models/issue';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DraggableCard} from '../../LayoutComponents/DraggableCard';
import {BoardList} from '../../LayoutComponents/BoardList';
import {ItemTypes} from '../../../common/constants';

export const IssueBoard: React.FC = () => {
    const apiClient = WebApiClient();
    const [loading, setLoading] = useState(true);
    const [issues, setIssues] = useState([] as Issue[]);

    React.useEffect(() => {
        populateIssues();
    }, []);

    type tListNames = {
        [key: string]: string
    };
    const listNames: tListNames = {
        "Submitted": "Submitted",
        "InReview": "In review",
        "ClosedWithoutAction": "Closed without action",
        "Resolved": "Resolved",
    };

    function renderIssuesTable() {
        return (
            <div style={{width: "100%"}}>
            <h4>Issues</h4>
                <div className="board-container">
                    {Object.entries(listNames).map(([issueStatus, title]) => 
                        <BoardList key={issueStatus} type={ItemTypes.Issue} title={title} status={issueStatus}> 
                            {issues.map(issue => 
                                issue.issueStatus === issueStatus &&
                                <DraggableCard 
                                    key={issue.id} 
                                    id={issue.id} 
                                    type={ItemTypes.Issue} 
                                    onDrop={onDrop}
                                    onClick={() => {}}>
                                        <div className="metafield">{issue.contactEmail}</div>
                                        <div className="metafield">{new Date(issue.occurred).toLocaleString()}</div>
                                        <div className="content">{issue.message}</div>
                                </DraggableCard>
                            )}
                        </BoardList>
                    )}
                </div>
            </div>
        );
    }

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderIssuesTable();

    return (
        <DndProvider backend={HTML5Backend}>
            {contents}
        </DndProvider>
    );

    async function populateIssues() {
      try {
        const response = await apiClient.get<Issue[]>("api/issues");
        setLoading(false);
        setIssues(response);
      } catch (e) {
        console.log(e);
      }
    }

    async function onDrop(id: string, newStatus: string): Promise<void> {
        try {
          const data = {issueStatus: newStatus};
          await apiClient.put<Issue[]>(`api/issues/${id}`, data);
          populateIssues();
        } catch (e) {
          console.log(e);
        }
      }
};