import React from "react";

export function topicsFormatter(topics: { [key: string]: string }[], row: any, rowIndex: number, formatExtraData: any): JSX.Element {
    return (
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
    {topics.filter(Boolean).map((topic, i) => (
        <div key={i} style={{
            fontSize: "9px",
            padding: "1px 6px",
            margin: "2px 3px",
            borderRadius: "3px",
            color: "white",
            backgroundColor: "teal",
            whiteSpace: "nowrap"}}>
        {topic.name}
        </div>
    ))}
    </div>
);
}
