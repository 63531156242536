import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import Backdrop from "../../LayoutComponents/Backdrop/Backdrop";
import React, {useState} from "react";
import {WebApiClient} from "../../../common/webApiClient";
import {Topic} from "../../../models/Topic";
import {PagedResponse} from "../../../models/PagedResponse";
import {TopicModal} from "./TopicModal";
import './TopicsTable.css';
import {Button, Input, InputGroup} from "reactstrap";
import {createFilterQuery} from "../../../common/createFilterQuery";


export const TopicsTable = () => {
    const apiClient = WebApiClient();
    const [topics, setTopics] = useState([] as Topic[]);
    const [newTopicName, setNewTopicName] = useState("");
    const [error, setError] = useState(null as string | null);
    const [showModal, toggleModal] = useState(false);
    const [selectedTopic, selectTopic] = useState({} as Topic | undefined);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalSize, setTotalSize] = useState(undefined as number | undefined);
    const [currFilters, setFilters] = useState(null as {[key: string]: {[key:string]: any}} | null);

    React.useEffect(() => { populateTopics() }, [pageNumber, pageSize, currFilters]);

    const rows = topics.map(t => ({
        date: new Date(t.addedDate).toLocaleString(),
        ...t
    }));

    const columns = [{
        dataField: 'topicId',
        text: 'Topic Id',
        hidden: true,
    }, {
        dataField: 'date',
        text: 'Added date'
    }, {
        dataField: 'name',
        text: 'Name',
        filter: textFilter({
            delay: 300,
            style: {
                minWidth: "100px",
            }
        })
    }];

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            e.preventDefault();
            selectTopic(topics.find(t => t.topicId === row.topicId));
            toggleModal(true);
        }
    };

    return (
        <div style={{width: "100%"}}>
            <h4>Topics</h4>
            <InputGroup style={{maxWidth: "350px"}}>
                <Input type={"text"} value={newTopicName} placeholder={"Enter topic name..."} onChange={(e) => setNewTopicName(e.target.value)}/>
                <Button onClick={submitNewTopic} disabled={newTopicName.trim() === "" || newTopicName.length === 0}>Add new topic</Button>
            </InputGroup>
            {error && <div style={{color: "red"}}>{error}</div>}
            <BootstrapTable
                remote={{
                    pagination: true,
                    filter: true,
                }}
                pagination={paginationFactory({
                    page: pageNumber,
                    sizePerPage: pageSize,
                    sizePerPageList: [10, 20, 50, 100],
                    totalSize: totalSize,
                    showTotal: false,
                    onPageChange: (page) => setPageNumber(page),
                    onSizePerPageChange: (sizePerPage) => setPageSize(sizePerPage),
                })}
                filter={filterFactory()}
                onTableChange={onTableChange}
                bootstrap4={true}
                keyField='accountId'
                data={rows}
                columns={columns}
                striped={true}
                bordered={false}
                rowEvents={rowEvents}
                headerClasses={"posts-table__header"}
                bodyClasses={"posts-table__body"}
                hover={true}
            />
            {showModal && <Backdrop backdropClickHandler={backdropClickHandler} />}
            {showModal && <TopicModal topic={selectedTopic} closeModal={backdropClickHandler} onChange={updateSingleTopic} onDelete={deleteSingleTopic}/>}
        </div>
    )

    async function submitNewTopic() {
        const data = {topic: newTopicName}
        apiClient.post("api/topics", data).then(() => {
            populateTopics();
            setNewTopicName("");
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    }

    async function populateTopics() {
        try {
            const url = "api/topics" + createFilterQuery(currFilters, pageNumber, pageSize);
            console.log(url);
            const response = await apiClient.get<PagedResponse<Topic[]>>(url);
            updateState(response)
        } catch(e: any) {
            setError(e.message);
            console.log(e.message);
        }
    }

    function backdropClickHandler() {
        toggleModal(false);
    }

    function updateState(response: PagedResponse<Topic[]>): void {
        setTopics(response.data);
        setTotalSize(response.totalSize);
    }

    function onTableChange(type: any, newState: any): void {
        const {filters} = newState;
        setFilters(filters);
    }

    function updateSingleTopic(updatedTopic: Topic) {
        const index = topics.findIndex(t => t.topicId === updatedTopic.topicId);
        topics[index] = updatedTopic;
    }

    function deleteSingleTopic(topicName: string) {
        const index = topics.findIndex(t => t.name === topicName);
        topics.splice(index, 1);
    }
}