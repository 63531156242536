import * as React from 'react';
import {LoginForm} from './LoginForm';
import logo from '../../../images/logcast_logo.png';
import './LoginPage.css';

export const LoginPage = () => {
    return (
        <div className="main">
            <div className="login-container">
                <div><img src={logo} style={{maxHeight: "55px"}} alt={"Logcast logo"}/></div>
                <div><LoginForm /></div>
            </div>
        </div>
    )
};