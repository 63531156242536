import React from "react";
import styles from './WelcomeMessage.module.css';
import ReactPlayer from "react-player";
import {WelcomeMessageModel} from "../../../models/WelcomeMessageModel";

export const WelcomeMessage: React.FC<{message: WelcomeMessageModel}> = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.left_column}>
                <div className={styles.message}>{props.message.message}</div>
            </div>
            <div className={styles.right_column}>
                <div className={styles.date}>Uploaded: {(new Date(props.message.date)).toLocaleDateString()}</div>
                {props.message?.urls?.dashUrl && <ReactPlayer height="30px" width="480px" url={props.message.urls.dashUrl} config={{file: {forceDASH: true,}}} inline controls/>}
            </div>
        </div>
    )
}