import React, {useState} from "react";
import {WebApiClient} from "../../../common/webApiClient";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {PagedResponse} from "../../../models/PagedResponse";
import {Button, FormControl, FormGroup, Row, Col} from "react-bootstrap";
import {createFilterQuery} from "../../../common/createFilterQuery";
import {RedirectModel} from "../../../models/RedirectModels";

export const RedirectsTable: React.FC = () => {
    const apiClient = WebApiClient();
    const [error, setError] = useState(null as string | null);
    const [redirects, setRedirects] = useState([] as RedirectModel[]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortColumn, setSortColumn] = useState("createdAt");
    const [sortDescending, setSortDescending] = useState(true);
    const [totalSize, setTotalSize] = useState(undefined as number | undefined);
    const [currFilters, setFilters] = useState(null as {[key: string]: {[key:string]: any}} | null);

    const [urlToken, setUrlToken] = useState("");
    const [targetUrl, setTargetUrl] = useState("");

    React.useEffect(() => { populateRedirects() }, [pageNumber, pageSize, currFilters, sortColumn, sortDescending]);

    const rows = redirects.map(s => ({
        ...s,
        createdAt: new Date(s.createdAt).toLocaleString(),
    }));

    const columns = [{
        dataField: 'redirectId',
        text: 'Redirect Id',
        hidden: true,
    }, {
        dataField: 'shortenedUrlToken',
        text: 'Url Token',
        filter: textFilter({
            delay: 300,
            style: {
                minWidth: "100px",
            }
        })
    }, {
        dataField: 'targetUrl',
        text: 'Target Url',
        filter: textFilter({
            delay: 300,
            style: {
                minWidth: "100px",
            }
        })
    }, {
        dataField: 'numberOfRedirects',
        text: 'Redirects',
    }, {
        dataField: 'createdAt',
        text: 'Created At',
        sort: true,
    }, {
        dataField: 'delete',
        text: 'Delete',
        formatter: deleteButton
    }];

    return (
        <div style={{width: "100%"}}>
            <h4>Subscribers and Surveys</h4>
            <div style={{}}>
                <div style={{fontWeight: "bold"}}>Add new redirect:</div>
                <div style={{display: "flex", flexDirection: "row", width: "50%"}}>
                    <span style={{fontSize: "1rem", fontWeight: 400, lineHeight: "1.5", padding: "0.375rem 0 0.375rem 0.75rem"}}>logcast.io/l/</span>
                    <FormControl id={"url-token-field"} style={{width: "20%", paddingLeft: "0.1rem"}} type={"text"} placeholder={"Url-Token"} value={urlToken} onChange={(e) => setUrlToken(e.target.value)}/>
                    <FormControl id={"target-url-field"} style={{width: "40%", marginLeft: "10px"}} type={"text"} placeholder={"Target Url"} value={targetUrl} onChange={(e) => setTargetUrl(e.target.value)}/>
                    <Button onClick={() => createNewRedirect()}>Submit</Button>
                </div>
            </div>
            {error && <div style={{color: "red"}}>{error}</div>}
            <BootstrapTable
                remote={{
                    pagination: true,
                    filter: true,
                }}
                pagination={paginationFactory({
                    page: pageNumber,
                    sizePerPage: pageSize,
                    sizePerPageList: [10, 20, 50, 100],
                    totalSize: totalSize,
                    showTotal: false,
                    onPageChange: (page) => setPageNumber(page),
                    onSizePerPageChange: (sizePerPage) => setPageSize(sizePerPage),
                })}
                filter={filterFactory()}
                onTableChange={onTableChange}
                bootstrap4={true}
                keyField='accountId'
                data={rows}
                columns={columns}
                striped={true}
                bordered={false}
                headerClasses={"posts-table__header"}
                bodyClasses={"posts-table__body"}
                hover={true}
                defaultSorted={[{dataField: "createdAt", order: "desc"}]}
                filterPosition={"top"}
            />
        </div>
    )

    function deleteButton(data: any, row: RedirectModel, rowIndex: number, formatExtraData: any) {
        return (
            <Button onClick={() => deleteItem(row, rowIndex)}>Delete</Button>
        )
    }

    async function deleteItem(redirect: RedirectModel, rowIndex: number) {
        try {
            const url = 'api/redirects/' + redirect.redirectId;
            await apiClient.delete(url);
            let tmp = redirects;
            tmp.splice(rowIndex, 1);
            setRedirects(tmp);
        } catch(e: any) {
            setError(e.message);
            console.log(e.message);
        }
    }

    async function createNewRedirect() {
        try {
            const url = 'api/redirects';
            let target = targetUrl;
            if (!target.startsWith("http")) {
                target = "https://" + target;
            }
            const data = {
                ShortenedUrlToken: urlToken,
                TargetUrl: target,
            }
            const response = await apiClient.post<RedirectModel>(url, data);
            let tmp = redirects;
            tmp = [response, ...tmp];
            setRedirects(tmp);
        } catch(e: any) {
            setError(e.message);
            console.log(e.message);
        }
    }

    async function populateRedirects() {
        try {
            const url = 'api/redirects' + createFilterQuery(currFilters, pageNumber, pageSize, sortColumn, sortDescending);
            console.log(url);
            const response = await apiClient.get<PagedResponse<RedirectModel[]>>(url);
            updateState(response)
        } catch(e: any) {
            setError(e.message);
            console.log(e.message);
        }
    }


    function updateState(response: PagedResponse<RedirectModel[]>): void {
        setRedirects(response.data);
        setTotalSize(response.totalSize);
    }

    function onTableChange(type: any, newState: any): void {
        const {filters, sortField, sortOrder} = newState;
        setFilters(filters);
        setSortColumn(sortField);
        setSortDescending(sortOrder === "desc");
    }
}