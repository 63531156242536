import React, {FormEvent, useState} from "react";
import {WebApiClient} from "../../../common/webApiClient";
import {FAQItem} from "../../../models/FAQItem";
import {FAQModal} from "./FAQModal";

type FeedbackQACreateModalProps = {
    closeModal: () => void,
    onChange: (updatedFeedback: FAQItem) => void,
};

export const FAQCreateModal: React.FC<FeedbackQACreateModalProps> = ({closeModal, onChange}) => {
    const apiClient = WebApiClient();
    const [error, setError] = useState<null | string>(null);

    const createFeedback = (e: FormEvent, newQuestion?: string, newAnswer?: string) => {
        e.preventDefault();
        if (!newQuestion || !newAnswer) {
            setError("Both question and answer must be set!")
            return;
        }
        let data = {};
        data = {question: newQuestion, ...data};
        data = {answer: newAnswer, ...data};
        apiClient.post<FAQItem>('api/feedback', data).then((faqItem) => {
            onChange(faqItem);
            closeModal();
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    }

    return (
        <FAQModal originalQuestion={""} originalAnswer={""} type={"Create"} error={error} closeModal={closeModal} onSubmit={createFeedback}/>
    );
}
