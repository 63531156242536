import React, {useState} from 'react';
import {WebApiClient} from '../../../common/webApiClient';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {FAQItem} from "../../../models/FAQItem";
import {FAQList} from "./FAQList";
import {FeedbackQAUpdateModal} from "./FAQUpdateModal";
import Backdrop from "../../LayoutComponents/Backdrop/Backdrop";
import {FAQCreateModal} from "./FAQCreateModal";


export const FAQBoard: React.FC = () => {
    const apiClient = WebApiClient();
    const [loading, setLoading] = useState(true);
    const [faqs, setFAQs] = useState([] as FAQItem[]);
    const [showModal, setShowModal] = useState(false);
    const [createFeedback, setCreateFeedback] = useState(false);
    const [clickedFeedback, setClickedFeedback] = useState(null as FAQItem | null);

    React.useEffect(() => {
        populateFAQ();
    }, []);

    let contents = loading
        ? <p><em>Loading...</em></p>
        : <FAQList faqs={faqs} setFAQs={setFAQs} updateCardPosition={updateFeedbackPosition} openModal={openModal}/>;
        
    return (
        <div style={{width: "100%"}}>
            <DndProvider backend={HTML5Backend}>
                <h4>Frequently Asked Questions</h4>
                {contents}
            </DndProvider>
            {showModal && <Backdrop backdropClickHandler={closeModal} />}
            {showModal && clickedFeedback && <FeedbackQAUpdateModal faqItem={clickedFeedback} closeModal={closeModal} onChange={updateFeedback} onDelete={onDelete}/>}
            {showModal && createFeedback && <FAQCreateModal closeModal={closeModal} onChange={updateFeedback}/>}
        </div>
    );

    async function populateFAQ() {
        try {
            const response = await apiClient.get<FAQItem[]>("api/feedback");
            setLoading(false);
            const sortedResponse = response.sort((a, b) => a.orderIndex-b.orderIndex)
            setFAQs(sortedResponse);
        } catch (e: any) {
            e.json().then((msg: any) => {
                console.log(e.statusCode + " " + e.statusText + ": " + msg);
            })
        }
    }

    function openModal(id: string) {
        if (id === "create") {
            setShowModal(true)
            setCreateFeedback(true)
        } else {
            setShowModal(true);
            const faqItem = faqs.filter((f) => f.id === id)[0]
            setClickedFeedback(faqItem);
        }
    }

    function closeModal() {
        setShowModal(false);
        setCreateFeedback(false);
        setClickedFeedback(null);
    }

    function onDelete(id: string) {
        const index = faqs.findIndex(f => f.id === id)
        faqs.splice(index, 1)
    }

    function updateFeedback(updatedFeedback: FAQItem) {
        const index = faqs.findIndex(f => f.id === updatedFeedback.id)
        if (index === -1) {
            faqs.push(updatedFeedback);
        } else {
            faqs[index] = updatedFeedback;
        }
    }

    async function updateFeedbackPosition(id: string, to: number) {
        try {
            const data = {orderIndex: to}
            await apiClient.put<FAQItem[]>("api/feedback/" + id, data)
        } catch (e: any) {
            e.json().then((msg: any) => {
                console.log(e.statusCode + " " + e.statusText + ": " + msg);
            })
        }
    }
};