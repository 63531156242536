import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './ProjectModal.module.css';
import {Button, Image} from "react-bootstrap";
import {WebApiClient} from "../../../common/webApiClient";
import {ClickableLog, ClickableUserName} from "../../LayoutComponents/ClickableLinks/ClickableInternalLinks";
import {Project, ProjectPost} from "../../../models/Project";
import {Log} from "../../../models/Log";
import {Form, FormGroup, Input, InputGroup, InputGroupAddon, Label} from "reactstrap";
import {ProjectStatus} from "../../../common/constants";
import {PagedResponse} from "../../../models/PagedResponse";
import TextareaAutosize from "react-textarea-autosize";

type ProjectModalProps = {
    project: Project | undefined,
    closeModal: () => void,
    onChange: (project: Project) => void,
};

export const ProjectModal: React.FC<ProjectModalProps> = ({project, closeModal, onChange}) => {
    const apiClient = WebApiClient();
    const [posts, setPosts] = useState([] as Log[]);
    const [updatedProjectStatus, setUpdatedProjectStatus] = useState(project?.projectStatus);
    const [updatedLogDescription, setUpdatedLogDescription] = useState(project?.description);
    const [error, setError] = useState("");

    useEffect(() => {
        if (!project) return;

        fetchProjectPosts();
    }, [project]);

    const author = () => (
        <div className={styles.authorWrapper}>
            <div className={styles.avatar}>
                {project?.account?.profileImage ?
                    <Image src={project?.account?.profileImage.imageUrl} style={{width: "64px"}}/> :
                    <FontAwesomeIcon icon={"user-circle"}/>}
            </div>
            <div className={styles.username}>
                {ClickableUserName(project?.account?.userName)}
            </div>
        </div>
    );

    const location = () => (
        <div className={styles.projectLocation}>
            {project?.location?.country}
        </div>
    );

    const postedDate = () => (
        <div className={styles.projectTime}>
            {project && new Date(project.createdDate).toLocaleString()}
        </div>
    );

    const closeModalButton = () => (
        <FontAwesomeIcon className={styles.closeButton} icon="times" onClick={closeModal}/>
    );

    const postSummary = (log: Log) => (
        <div className={styles.post}>
            <div className={styles.postAuthor}>{ClickableUserName(log.userName)}</div>
            <div className={styles.postMessage}>{ClickableLog(log.title, log.logId)}</div>
            <div className={styles.postDate}>{new Date(log.postedDate).toLocaleString()}</div>
        </div>
    );

    return (
        <div className={styles.container}>
            <div>
                {author()}
            </div>
            <div>
                {location()}
            </div>
            <div>
                {postedDate()}
            </div>
            <div className={styles.postList}>
                {posts?.map(p => postSummary(p))}
            </div>
            {error && <div style={{color: "red"}}>{error}</div>}
            <Form style={{marginTop: "15px"}}>

                <FormGroup>
                <Label>Description</Label>
                <InputGroup>
                    <TextareaAutosize className={"form-control"} value={updatedLogDescription} onChange={(e) => setUpdatedLogDescription(e.target.value)} />
                </InputGroup>
                </FormGroup>
                <FormGroup style={{marginTop: "15px"}}>
                    <Label>Status</Label>
                    <Input type={"select"} value={updatedProjectStatus} onChange={(e) => setUpdatedProjectStatus(e.target.value)}>
                        {Object.values(ProjectStatus).map((projectStatus, i) => (
                            <option key={i} value={projectStatus}>{projectStatus}</option>
                        ))}
                    </Input>
                </FormGroup>
                    <Button variant={"danger"} onClick={updateStatus}>Update</Button>
            </Form>
            {closeModalButton()}
        </div>
    );

    function updateStatus() {
        const data = {projectStatus: updatedProjectStatus, description: updatedLogDescription};
        apiClient.put<Project>("api/projects/" + project?.projectId, data).then((t) => {
            onChange(t);
            closeModal();
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    }

    function fetchProjectPosts() {
        apiClient.get<PagedResponse<Log[]>>("/api/projects/" + project?.projectId + "/logs")
            .then((response) => {
                setPosts(response.data);
            }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    }
};
