import { IHttpClient } from "./httpClient";
import Cookie from "js-cookie";

export function WebApiClient(): IHttpClient {
    const webApiClient: IHttpClient = {
        async get<T>(url: string, noRetry?: boolean): Promise<T> {
            let response = await fetch(url, {
                headers: getHeaders({}),
                credentials: "same-origin",
            });

            if (response.status === 401 || response.status === 403) {
                if (noRetry) {
                    Cookie.remove("signed_in");
                } else {
                    const authenticated = await reAuthenticate();
                    if (!authenticated) throw response;
                    return await this.get<T>(url, true);
                }
            }

            if (!response.ok) await handleError(response);

            return parseResponse<T>(response);
        },

        async post<T>(
            url: string,
            data?: any,
            appendHeaders?: Record<string, string>,
            noRetry?: boolean
        ) {
            const response = await fetch(url, {
                headers: getHeaders(appendHeaders || {}),
                method: "post",
                credentials: "same-origin",
                body: data ? JSON.stringify(data) : undefined,
            });

            if (response.status === 401 || response.status === 403) {
                if (noRetry) {
                    Cookie.remove("signed_in");
                } else {
                    const authenticated = await reAuthenticate();
                    if (!authenticated) throw response;
                    return await this.post<T>(url, data, appendHeaders, true);
                }
            }

            if (!response.ok) await handleError(response);

            return parseResponse<T>(response);
        },

        async postFile<T>(
            url: string,
            file: File,
            appendHeaders?: Record<string, string>,
            noRetry?: boolean
        ) {
            const formData = new FormData();
            formData.append("File", file);
            const response = await fetch(url, {
                headers: { Accept: "application/json" },
                method: "post",
                credentials: "same-origin",
                body: formData,
            });

            if (response.status === 401 || response.status === 403) {
                if (noRetry) {
                    Cookie.remove("signed_in");
                } else {
                    const authenticated = await reAuthenticate();
                    if (!authenticated) throw response;
                    return await this.postFile<T>(
                        url,
                        file,
                        appendHeaders,
                        true
                    );
                }
            }

            if (!response.ok) await handleError(response);

            return parseResponse<T>(response);
        },

        async getFile(
            url: string,
            noRetry?: boolean
        ): Promise<{ filename: string; content: Blob }> {
            let response = await fetch(url, {
                headers: getHeaders({}),
                credentials: "same-origin",
            });

            if (response.status === 401 || response.status === 403) {
                if (noRetry) {
                    Cookie.remove("signed_in");
                } else {
                    const authenticated = await reAuthenticate();
                    if (!authenticated) throw response;
                    return await this.getFile(url, true);
                }
            }

            if (!response.ok) await handleError(response);

            //Content-Disposition: attachment; filename=Logcast-Accounts.csv; filename*=UTF-8''Logcast-Accounts.csv
            const cd = response.headers.get("Content-Disposition");
            let filename = "export.csv";
            if (cd) {
                filename = cd.split(";")[1].split("=")[1];
            }
            return {
                filename,
                content: await response.blob(),
            };
        },

        async put<T>(
            url: string,
            data?: any,
            appendHeaders?: Record<string, string>,
            noRetry?: boolean
        ) {
            const response = await fetch(url, {
                headers: getHeaders(appendHeaders || {}),
                method: "put",
                credentials: "same-origin",
                body: data ? JSON.stringify(data) : undefined,
            });

            if (response.status === 401 || response.status === 403) {
                if (noRetry) {
                    Cookie.remove("signed_in");
                } else {
                    const authenticated = await reAuthenticate();
                    if (!authenticated) throw response;
                    return await this.put<T>(url, data, appendHeaders, true);
                }
            }

            if (!response.ok) await handleError(response);

            return parseResponse<T>(response);
        },

        async delete<T>(
            url: string,
            data?: any,
            appendHeaders?: Record<string, string>,
            noRetry?: boolean
        ) {
            const response = await fetch(url, {
                headers: getHeaders(appendHeaders || {}),
                method: "delete",
                credentials: "same-origin",
                body: data ? JSON.stringify(data) : undefined,
            });

            if (response.status === 401 || response.status === 403) {
                if (noRetry) {
                    Cookie.remove("signed_in");
                } else {
                    const authenticated = await reAuthenticate();
                    if (!authenticated) throw response;
                    return await this.delete<T>(url, data, appendHeaders, true);
                }
            }

            if (!response.ok) await handleError(response);

            return parseResponse<T>(response);
        },
    };

    return webApiClient;
}

async function handleError(response: Response) {
    let message = "";
    try {
        message = await response.text();
    } catch (e) {}
    throw Error(response.status + " " + response.statusText + ": " + message);
}

async function reAuthenticate() {
    const refresh = await fetch("api/authentication/token", {
        headers: getHeaders({}),
        method: "post",
        credentials: "same-origin",
        // Refresh token sent by cookie - not body
        body: JSON.stringify({ accessToken: "", refreshToken: "" }),
    });
    if (!refresh.ok) {
        Cookie.remove("signed_in");
        return false;
    }
    Cookie.set("signed_in", "true");
    return true;
}

async function parseResponse<T>(response: Response) {
    let result = {};
    try {
        result = await response.json();
    } catch (e) {
        console.log("Response could not be parsed");
    }
    return result as T;
}

function getHeaders(appendHeaders: Record<string, string>): Headers {
    const headers = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        ...appendHeaders,
    });
    return headers;
}
