import React from 'react';
import {Navbar, NavItem, NavLink} from 'reactstrap';
import DrawerToggleButton from './SideDrawer/DrawerToggleButton';
import './NavMenu.css';
import {Link} from "react-router-dom";

type NavMenuProps = {
    drawerClickHandler: () => void,
    logoutHandler: () => Promise<void>
}

export const NavMenu: React.FC<NavMenuProps> = ({drawerClickHandler, logoutHandler}) => {

    return (
        <header>
            <Navbar className="navbar-expand-sm border-bottom box-shadow">
                <div className="navbar-container">
                    <div className="left">
                        <DrawerToggleButton click={drawerClickHandler}/>
                    </div>
                    <div className="right">
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink style={{fontWeight: "bold"}} tag={Link} className="text-dark" to="/" onClick={logoutHandler}>Log out </NavLink>
                            </NavItem> 
                        </ul>
                    </div>
                </div>
            </Navbar>
        </header>
    );
};

