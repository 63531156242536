import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {Log} from '../../../models/Log';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './LogModal.module.css';
import {Button, FormCheck} from "react-bootstrap";
import {WebApiClient} from "../../../common/webApiClient";
import TextareaAutosize from "react-textarea-autosize";
import {Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label} from "reactstrap";
import {LogStatus} from "../../../common/constants";
import {LogView} from "./LogView";
import {createFilterQuery} from "../../../common/createFilterQuery";
import {PagedResponse} from "../../../models/PagedResponse";
import {Topic} from "../../../models/Topic";

type PostModalProps = {
  log: Log | undefined,
  closeModal: () => void,
  onChange: (post: Log) => void,
};

export const LogModal: React.FC<PostModalProps> = ({ log, closeModal, onChange }) => {
  const apiClient = WebApiClient();
  console.log({log})
  const [error, setError] = useState("");
  const [reportNote, setReportNote] = useState("");
  const [updatedLogStatus, setUpdatedLogStatus] = useState(log?.logStatus);
  const [updatedIsExcludedFromFeed, setUpdatedIsExcludedFromFeed] = useState(log?.isExcludedFromFeed);
  const [updatedLogTitle, setUpdatedLogTitle] = useState(log?.title);
  const [updatedLogDescription, setUpdatedLogDescription] = useState(log?.description);
  const [updatedLogTopicIds, setupdatedLogTopicIds] = useState(log?.topics.map(t => t.topicId) ?? [])
  const [possibleTopics, setPossibleTopics] = useState([] as Topic[]);

  useEffect(() => {
    let active = true;

    const fetchTopics = async () => {
      const url = "api/topics?pageSize=1000";
      const response = await apiClient.get<PagedResponse<Topic[]>>(url);
      if (active) {
        setPossibleTopics(response.data);
      }
    }
    fetchTopics();
    return () => {
      active = false;
    };
  }, []);

  const closeModalButton = () => (
    <FontAwesomeIcon className={styles.closeButton} icon="times" onClick={closeModal} />
  );

  const handleTopicChange = () => {
    const element = document.getElementById("multiple") as HTMLSelectElement
    let selectedItems = [] as string[];
    for (let i = 0; i < element.selectedOptions.length; i++) {
      const item = element?.selectedOptions?.item(i)
      if (item)
        selectedItems.push(item.value);
    }
    setupdatedLogTopicIds(selectedItems);
  };

  return (
    <div className={styles.container}>
      <LogView log={log}/>
      {error && <div style={{color: "red"}}>{error}</div>}
      <InputGroup style={{marginTop: "15px"}}>
        <TextareaAutosize className={"form-control"} value={reportNote} onChange={(e) => setReportNote(e.target.value)}/>
        <InputGroupAddon addonType={"append"}>
          <Button variant={"danger"} onClick={reportPost}>{"Report"}</Button>
        </InputGroupAddon>
      </InputGroup>
      <Form style={{marginTop: "15px"}}>
        <FormGroup>
          <Label>Title</Label>
          <Input type={"text"} value={updatedLogTitle} onChange={(e) => setUpdatedLogTitle(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <InputGroup>
            <TextareaAutosize className={"form-control"} value={updatedLogDescription} onChange={(e) => setUpdatedLogDescription(e.target.value)} />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label>Status</Label>
          <Input type={"select"} value={updatedLogStatus} onChange={(e) => setUpdatedLogStatus(e.target.value)}>
            {Object.values(LogStatus).map((postStatus, i) => (
                <option key={i} value={postStatus}>{postStatus}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup check>
          <Input type={"checkbox"} checked={updatedIsExcludedFromFeed} onChange={() => setUpdatedIsExcludedFromFeed(!updatedIsExcludedFromFeed)} />
          <Label check>Exclude from Feed</Label>
        </FormGroup>
        <FormGroup style={{marginTop: "15px"} }>
          <Label>Topics</Label>
          <Input id={"multiple"} type={"select"} value={updatedLogTopicIds} multiple onChange={(e) => handleTopicChange()}>
            {Object.values(possibleTopics).map((logTopic, i) => (
                <option key={i} value={logTopic.topicId}>{logTopic.name}</option>
            ))}
          </Input>
        </FormGroup>
        <Button type={"submit"} onClick={updateLog}>Update Log</Button>
      </Form>
      {closeModalButton()}
    </div>
  );

  function topicsMatch(topics: Topic[] | undefined, updatedTopicIds: string[]) {
    if (topics?.length !== updatedTopicIds.length) return false;

    topics.forEach(t => {
      if (!updatedTopicIds.some(u => u === t.topicId)) return false;
    })

    return true;
  }

  function updateLog(e: FormEvent) {
    e.preventDefault();
    let data = {};
    if (log?.title !== updatedLogTitle) data = {title: updatedLogTitle, ...data};
    if (log?.description !== updatedLogDescription) data = {description: updatedLogDescription, ...data};
    if (log?.logStatus !== updatedLogStatus) data = {logStatus: updatedLogStatus, ...data};
    if (log?.isExcludedFromFeed !== updatedIsExcludedFromFeed) data = {isExcludedFromFeed: updatedIsExcludedFromFeed, ...data};
    if (!topicsMatch(log?.topics, updatedLogTopicIds)) data = {logTopics: updatedLogTopicIds, ...data};
    apiClient.put<Log>("api/logs/" + log?.logId, data).then((t) => {
      onChange(t);
      closeModal();
    }).catch((e: Error) => {
      setError(e.message)
      console.log(e.message)
    });
  }

  function reportPost() {
    const data = {message: reportNote}
    apiClient.put<Log>("api/logs/" + log?.logId + "/report", data).then((t) => {
      onChange(t);
      closeModal();
    }).catch((e: Error) => {
      setError(e.message)
      console.log(e.message)
    });
  }
};
