import React, {FormEvent, useState} from "react";
import {WebApiClient} from "../../../common/webApiClient";
import {FAQItem} from "../../../models/FAQItem";
import {FAQModal} from "./FAQModal";

type FAQUpdateModal = {
    faqItem: FAQItem | undefined,
    closeModal: () => void,
    onChange: (updatedFeedback: FAQItem) => void,
    onDelete: (id: string) => void,
};

export const FeedbackQAUpdateModal: React.FC<FAQUpdateModal> = ({faqItem, closeModal, onChange, onDelete}) => {
    const apiClient = WebApiClient();
    const [error, setError] = useState<null | string>(null);

    const updateFeedback = (e: FormEvent, newQuestion?: string, newAnswer?: string) => {
        if (!newQuestion || !newAnswer) {
            setError("Both question and answer must be set")
            return;
        }
        e.preventDefault();
        let data = {};
        if (!faqItem) {
            setError("No FAQ to update")
            return
        }
        data = faqItem.question !== newQuestion ? {question: newQuestion, ...data} : data;
        data = faqItem.answer !== newAnswer ? {answer: newAnswer, ...data} : data;
        apiClient.put<FAQItem>('api/feedback/' + faqItem.id, data).then((faqItem) => {
            onChange(faqItem);
            closeModal();
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    }

    const deleteFeedback = (e: FormEvent) => {
        e.preventDefault();
        if (!faqItem) {
            setError("No FAQ to delete")
            return
        }
        apiClient.delete('api/feedback/' + faqItem.id).then(() => {
            onDelete(faqItem.id)
            closeModal()
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    }

    return (
        <FAQModal originalQuestion={faqItem?.question} originalAnswer={faqItem?.answer} type={"Update"} error={error} closeModal={closeModal} onSubmit={updateFeedback} onDelete={deleteFeedback}/>
    );

}
