import React, {useState} from "react";
import {Button, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import {WebApiClient} from "../../../common/webApiClient";

export const ResetPasswordForm = () => {
    const [identifier, setIdentifier] = useState("");
    const [error, setError] = useState("");
    const [response, setResponse] = useState("");

    function validateForm() {
        return identifier.length > 0;
    }

    const handleLogin = async (event: any) => {
        event.preventDefault();
        const apiClient = WebApiClient();
        try {
            await apiClient.post("api/system-accounts/reset-password", {
                "identifier": identifier,
            });
            setResponse("Password reset email sent!")
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        }
    };

    return (
        <Row className="Login justify-content-center align-items-center">
            <form onSubmit={handleLogin}>
                <FormGroup controlId="identifier">
                    <FormLabel>Email or Username</FormLabel>
                    <FormControl
                        autoFocus
                        type="text"
                        value={identifier}
                        onChange={e => setIdentifier(e.target.value)}
                    />
                </FormGroup>
                <Button variant="danger" block disabled={!validateForm()} type="submit">
                    Reset Password
                </Button>
                {error && <div style={{color: "red"}}>{error}</div>}
                {response && <div style={{color: "green"}}>{response}</div>}
            </form>
        </Row>
    );
}