import React, {useEffect, useState} from "react";
import styles from "./AnalyticsTable.module.css";
import {WebApiClient} from "../../../common/webApiClient";
import {AnalyticsValueResponse} from "../../../models/AnalyticsValueResponse";

export const AnalyticsTable: React.FC = () => {
    const [globalFromDate, setGlobalFromDate] = useState("");
    const [globalToDate, setGlobalToDate] = useState("");

    return (
        <div className={styles.container}>
            <div className={styles.header}>Analytics</div>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th className={styles.table_value}>Count</th>
                        <th className={styles.table_name}>Type</th>
                        <th className={styles.table_name}>Between</th>
                        <th className={styles.table_date}><input type={"date"} value={globalFromDate} onChange={(e) => setGlobalFromDate(e.target.value)}/></th>
                        <th>and</th>
                        <th className={styles.table_date}><input type={"date"} value={globalToDate} onChange={(e) => setGlobalToDate(e.target.value)}/></th>
                    </tr>
                </thead>
                <tbody>
                    <AnalyticsTableRow className={styles.table_odd_row} name={"Users"} url={"api/analytics/users"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_even_row} name={"Active Users"} url={"api/analytics/active-users"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_odd_row} name={"Active Posters"} url={"api/analytics/active-users"} extraQuery={"onlyCreators=true"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_even_row} name={"Logs"} url={"api/analytics/logs"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_odd_row} name={"Log minutes"} url={"api/analytics/logs"} extraQuery={"countMinutes=true"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_even_row} name={"Log Likes"} url={"api/analytics/likes"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_odd_row} name={"Trailers"} url={"api/analytics/trailers"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_even_row} name={"Direct Logs"} url={"api/analytics/messages"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_odd_row} name={"Direct Logs and Messages"} url={"api/analytics/messages"} extraQuery={"onlyAudio=false"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_even_row} name={"Unique streams"} url={"api/analytics/logs-streamed"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_odd_row} name={"Unique streamed minutes"} url={"api/analytics/logs-streamed"} extraQuery={"countMinutes=true"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                    <AnalyticsTableRow className={styles.table_even_row} name={"Total number of streams"} url={"api/analytics/streams"} />
                    <AnalyticsTableRow className={styles.table_odd_row} name={"Total streamed minutes"} url={"api/analytics/streams"} extraQuery={"countMinutes=true"} />
                    <AnalyticsTableRow className={styles.table_even_row} name={"Total images"} url={"api/analytics/images"} globalFromDate={globalFromDate} globalToDate={globalToDate}/>
                </tbody>
            </table>
        </div>
    )
}

interface IAnalyticsTableRow {
    className?: string;
    name: string;
    url: string;
    globalFromDate?: string;
    globalToDate?: string;
    extraQuery?: string;
}

const AnalyticsTableRow: React.FC<IAnalyticsTableRow> = (props) => {
    const [value, setValue] = useState("Loading..." as "Loading..." | "Error..." | number);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const apiClient = WebApiClient();

    useEffect(() => {
        if (props.globalFromDate)
            setFromDate(props.globalFromDate);
    }, [props.globalFromDate])

    useEffect(() => {
        if (props.globalToDate)
            setToDate(props.globalToDate);
    }, [props.globalToDate])

    useEffect(() => {
        let query = (fromDate || toDate || props.extraQuery) ? "?" : "";
        query = query + (fromDate ? ("fromDate=" + fromDate + "&") : "");
        query = query + (toDate ? ("toDate=" + toDate + "&") : "");
        query = query + (props.extraQuery !== undefined ? props.extraQuery : "");
        apiClient.get<AnalyticsValueResponse>(props.url + query)
            .then(response => {
                setValue(response.value);
            }).catch(e => {
                setValue("Error...");
        })
    }, [fromDate, toDate, props.url])

    return (
        <tr className={props.className}>
            <th>{value}</th>
            <th>{props.name}</th>
            <th>between</th>
            <th>{props.globalFromDate  !== undefined ? <input type={"date"} value={fromDate} onChange={(e) => setFromDate(e.target.value)}/> : null}</th>
            <th>and</th>
            <th>{props.globalFromDate  !== undefined ? <input type={"date"} value={toDate} onChange={(e) => setToDate(e.target.value)}/> : null}</th>
        </tr>
    )
}