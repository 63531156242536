import React from 'react';

import './Backdrop.css'

type BackDropProps = {
  backdropClickHandler: () => void
}

const backdrop: React.FC<BackDropProps> = ({backdropClickHandler}) => (
  <div className="backdrop" onClick={backdropClickHandler}/>
);

export default backdrop;