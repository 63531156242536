export interface ProgrammedNotification {
    id: string;
    frequency: string;
    target: string;
    executionDate: string | null;
    executionDaysOfWeek: string;
    text: string;
    title: string;
    status: string;
    createdDate: string;
}

export const NotificationFrequency = {
    Once: "Once",
    Weekly: "Weekly",
    Daily: "Daily",
    Monthly: "Monthly",
    Yearly: "Yearly",
};

export const NotificationTarget = {
    All: "All",
    Standard: "Standard",
    Creators: "Creators",
};

export const ProgrammedNotificationStatus = {
    Active: "Active",
    Inactive: "Inactive",
};
