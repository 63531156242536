import React, { useState } from "react";
import { WebApiClient } from "../../../common/webApiClient";
import { AccountProfileFull } from "../../../models/AccountProfile";
import { PagedResponse } from "../../../models/PagedResponse";
import filterFactory, {
    selectFilter,
    textFilter,
} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./AccountsTable.css";
import Backdrop from "../../LayoutComponents/Backdrop/Backdrop";
import { AccountModal } from "./AccountModal";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import _ from "lodash";
import * as QueryString from "query-string";
import { createFilterQuery } from "../../../common/createFilterQuery";
import * as CSS from "csstype";
import { SellerStatus } from "../../../common/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

export const AccountsTable: React.FC = () => {
    const apiClient = WebApiClient();
    const [accounts, setAccounts] = useState([] as AccountProfileFull[]);
    const [showModal, toggleModal] = useState(false);
    const [selectedAccount, selectAccount] = useState(
        {} as AccountProfileFull | undefined
    );
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortColumn, setSortColumn] = useState("signUpDate");
    const [sortDescending, setSortDescending] = useState(true);
    const [totalSize, setTotalSize] = useState(undefined as number | undefined);
    const [currFilters, setFilters] = useState(
        null as { [key: string]: { [key: string]: any } } | null
    );

    React.useEffect(() => {
        let active = true;

        const populateAccounts = async () => {
            try {
                const url =
                    "api/accounts" +
                    createFilterQuery(
                        currFilters,
                        pageNumber,
                        pageSize,
                        sortColumn,
                        sortDescending
                    );
                console.log(url);
                const response = await apiClient.get<
                    PagedResponse<AccountProfileFull[]>
                >(url);
                if (active) {
                    updateState(response);
                }
            } catch (e) {
                console.log((e as any).message);
            }
        };
        populateAccounts();
        return () => {
            active = false;
        };
    }, [pageNumber, pageSize, currFilters, sortColumn, sortDescending]);

    const rows = accounts.map((a) => ({
        ...a,
        sellerStatus: a.sellerConnectionAccount?.status ?? " ",
        signUpDate: new Date(a.signUpDate).toLocaleString(),
        suspendedUntil: new Date(a.suspendedUntil).toLocaleString(),
    }));

    const queryParameters = QueryString.parse(window.location.search);

    const columns = [
        {
            dataField: "accountId",
            text: "Account Id",
            style: {
                textOverflow: "ellipsis",
                overflow: "hidden",
                "white-space": "nowrap",
            },
        },
        {
            dataField: "signUpDate",
            text: "Signup Date",
            sort: true,
        },
        {
            dataField: "userName",
            text: "Username",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.userName,
            }),
        },
        {
            dataField: "name",
            text: "Name",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.name,
            }),
        },
        {
            dataField: "email",
            text: "Email",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.email,
            }),
            style: { width: "200px", "overflow-wrap": "break-word" },
        },
        {
            dataField: "phoneNumber",
            text: "Phone",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.phoneNumber,
            }),
        },
        {
            dataField: "isTopCreator",
            text: "Top Creator",
            filter: selectFilter({
                delay: 300,
                options: { true: "true", false: "false" },
                placeholder: " ",
            }),
            headerStyle: { width: "100px" },
        },
        {
            dataField: "isExcludedFromFeed",
            text: "Exclude from Feed",
            filter: selectFilter({
                delay: 300,
                options: { true: "true", false: "false" },
                placeholder: " ",
            }),
            headerStyle: { width: "100px" },
        },
        {
            dataField: "numberOfFollowers",
            text: "Followers",
            sort: true,
            headerStyle: { width: "80px" },
        },
        {
            dataField: "numberOfFollows",
            text: "Follows",
            sort: true,
            headerStyle: { width: "80px" },
        },
        {
            dataField: "numberOfStreams",
            text: "Streams",
            headerStyle: { width: "80px" },
        },
        {
            dataField: "sellerStatus",
            text: "Seller",
            sort: true,
            filter: selectFilter({
                delay: 300,
                options: SellerStatus,
                placeholder: " ",
            }),
            headerStyle: { width: "100px" },
        },
        {
            dataField: "accountType",
            text: "Type",
            sort: true,
            headerStyle: { width: "100px" },
        },
        {
            dataField: "accountStatus",
            text: "Status",
            sort: true,
            headerStyle: { width: "100px" },
        },
    ];

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            e.preventDefault();
            selectAccount(accounts.find((a) => a.accountId === row.accountId));
            toggleModal(true);
        },
    };
    const downloadFile = (name: string, blob: Blob) => {
        var dlink = document.createElement("a");
        dlink.download = name;
        dlink.href = window.URL.createObjectURL(blob);
        dlink.onclick = function (e) {
            // revokeObjectURL needs a delay to work properly
            var that = this as any;
            setTimeout(function () {
                window.URL.revokeObjectURL(that.href);
            }, 1500);
        };

        dlink.click();
        dlink.remove();
    };
    const downloadExport = async () => {
        try {
            const url =
                "api/accounts/export" +
                createFilterQuery(
                    currFilters,
                    void 0,
                    void 0,
                    sortColumn,
                    sortDescending
                );
            console.log(url);
            const response = await apiClient.getFile(url);
            downloadFile(response.filename, response.content);
        } catch (e) {
            console.log((e as any).message);
        }
    };
    return (
        <div style={{ width: "100%" }} className="accounts-bs-table">
            <h4 className="d-flex justify-content-between">
                <span>Accounts</span>
                <Button variant="danger" onClick={downloadExport}>
                    Export
                </Button>
            </h4>
            <BootstrapTable
                remote={{
                    pagination: true,
                    sort: true,
                    filter: true,
                }}
                pagination={paginationFactory({
                    page: pageNumber,
                    sizePerPage: pageSize,
                    sizePerPageList: [10, 20, 50, 100],
                    totalSize: totalSize,
                    showTotal: false,
                    onPageChange: (page) => setPageNumber(page),
                    onSizePerPageChange: (sizePerPage) =>
                        setPageSize(sizePerPage),
                })}
                filter={filterFactory()}
                onTableChange={onTableChange}
                bootstrap4={true}
                keyField="accountId"
                data={rows}
                columns={columns}
                striped={true}
                bordered={false}
                rowEvents={rowEvents}
                headerClasses={"posts-table__header"}
                bodyClasses={"posts-table__body"}
                hover={true}
                defaultSorted={[{ dataField: "signUpDate", order: "desc" }]}
                filterPosition={"top"}
            />
            {showModal && (
                <Backdrop backdropClickHandler={backdropClickHandler} />
            )}
            {showModal && selectedAccount != undefined && (
                <AccountModal
                    accountId={selectedAccount.accountId}
                    closeModal={backdropClickHandler}
                    onChange={updateSingleAccount}
                />
            )}
        </div>
    );

    function updateSingleAccount(updatedAccount: AccountProfileFull) {
        const index = accounts.findIndex(
            (a) => a.accountId === updatedAccount.accountId
        );
        accounts[index] = {
            ...accounts[index],
            ..._.omitBy(updatedAccount, _.isNull),
        };
    }

    function backdropClickHandler(): void {
        toggleModal(false);
    }

    function updateState(response: PagedResponse<AccountProfileFull[]>): void {
        setAccounts(response.data);
        setTotalSize(response.totalSize);
    }

    function onTableChange(type: any, newState: any): void {
        const { filters, sortField, sortOrder } = newState;
        setFilters(filters);
        setSortColumn(sortField);
        setSortDescending(sortOrder === "desc");
    }
};
