import React, {useEffect, useState} from "react";
import styles from "./EndpointLog.module.css";
import {EndpointLogReport, EndpointLogSummary, ReportCounts} from "../../../models/EndpointLogReport";
import {WebApiClient} from "../../../common/webApiClient";

export const EndpointLog: React.FC = () => {
    const webClient = WebApiClient();
    const [eCount, setECount] = useState({} as ReportCounts);
    const [wCount, setWCount] = useState({} as ReportCounts);
    const [sCount, setSCount] = useState({} as ReportCounts);
    const [eList, setEList] = useState([] as EndpointLogSummary[]);
    const [wList, setWList] = useState([] as EndpointLogSummary[]);
    const [sList, setSList] = useState([] as EndpointLogSummary[]);
    const [error, setError] = useState("");

    useEffect(() => {
        updateSummary();
    }, [])

    return (
        <div id={styles.container}>
            <div className={styles.header}>
                Endpoint Log Summary
            </div>
            <div className={styles.error}>{error}</div>
            <div className={styles.grid}>
                <Counts header={"Errors"} counts={eCount}/>
                <Top header={"Top Errors"} logSummaries={eList}/>
                <Counts header={"Warnings"} counts={wCount}/>
                <Top header={"Top Warnings"} logSummaries={wList}/>
                <Counts header={"Successes"} counts={sCount}/>
                <Top header={"Top Successes"} logSummaries={sList}/>
            </div>
        </div>
    )

    function updateSummary() {
        webClient.get<EndpointLogReport>('/api/endpointlog/summary')
        .then(respose => {
            setECount(respose.errorCounts);
            setWCount(respose.warningCounts);
            setSCount(respose.successCounts);
            setEList(respose.topErrors.slice(0, 10));
            setWList(respose.topWarnings.slice(0, 10));
            setSList(respose.topSuccesses.slice(0, 10));
        })
        .catch(e => {
           setError(e.message);
           console.log(e.message);
        });
    }
}

const Top: React.FC<{header: string, logSummaries: EndpointLogSummary[]}> = (props) => {
    return (
        <div className={styles.top_container}>
            <div className={styles.top_header}>{props.header}</div>
            <table className={styles.top_table}>
                <thead>
                    <tr>
                        <th className={styles.top_table_http_code}>Http Code</th>
                        <th className={styles.top_table_method}>Method</th>
                        <th className={styles.top_table_path}>Route</th>
                        <th className={styles.top_table_avg}>Average Time</th>
                        <th className={styles.top_table_count}>Count</th>
                        <th className={styles.top_table_tot}>Total Time</th>
                    </tr>
                </thead>
                <tbody>
                {props.logSummaries.map((l, i) => {
                    return (
                        <tr className={i % 2 == 0 ? styles.top_table_even_row : styles.top_table_odd_row}>
                            <th>{l.httpCode}</th>
                            <th>{l.method}</th>
                            <th>{l.requestPath}</th>
                            <th>{Math.round(100 * l.totalTime / l.count) / 100}</th>
                            <th>{l.count}</th>
                            <th>{Math.round(100 * l.totalTime) / 100}</th>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

const Counts: React.FC<{header: string, counts: ReportCounts}> = (props) => {
    return (
        <div className={styles.counts_container}>
            <div className={styles.counts_col}>
                <div className={styles.counts_header}>{props.header}</div>
                <div className={styles.counts_row}>
                    <TimeCount header={"Last Hour"} count={props.counts?.hourCount}/>
                    <TimeCount header={"Last Day"} count={props.counts?.dayCount}/>
                    <TimeCount header={"Last Week"} count={props.counts?.weekCount}/>
                </div>
            </div>
        </div>
    )
}

const TimeCount: React.FC<{header: string, count: number}> = (props) => {
    return (
        <div className={styles.time_count_col}>
            <div className={styles.time_count_header}>{props.header}</div>
            <div className={styles.time_count_count}>{props.count}</div>
        </div>
    )
}