import React from "react";
import {useParams} from "react-router";
import * as QueryString from "query-string";
import logo from "../../../images/logcast_logo.png";
import {ResetPasswordForm} from "../ResetPassword/ResetPasswordForm";
import {ExternalChangePasswordForm} from "./ExternalChangePasswordForm";
interface RouteParams {
    accountId: string;
  }
export const ExternalChangePasswordPage: React.FC = () => {
    const { accountId } = useParams<RouteParams>();
    const queryParameters = QueryString.parse(window.location.search);
    const token = queryParameters["token"] as string;

    return (
        <div className="main">
            <div className="login-container">
                <a href={"/"}><img src={logo} style={{maxHeight: "55px"}} alt={"Logcast logo"}/></a>
                <div><ExternalChangePasswordForm accountId={accountId} token={token} /></div>
            </div>
        </div>
    )
}