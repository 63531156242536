import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
    selectFilter,
    textFilter,
} from "react-bootstrap-table2-filter";
import { WebApiClient } from "../../../common/webApiClient";
import { PagedResponse } from "../../../models/PagedResponse";
import Backdrop from "../../LayoutComponents/Backdrop/Backdrop";
import "./ProjectsTable.css";
import _ from "lodash";
import { createFilterQuery } from "../../../common/createFilterQuery";
import * as QueryString from "query-string";
import { Project } from "../../../models/Project";
import { ProjectModal } from "./ProjectModal";
import { ProjectStatus } from "../../../common/constants";
import { topicsFormatter } from "../../LayoutComponents/Tooltip/TopicsFormatter";
import { tagsFormatter } from "../../LayoutComponents/Tooltip/TagsFormatter";

export const ProjectsTable: React.FC = () => {
    const apiClient = WebApiClient();
    const [projects, setProjects] = useState([] as Project[]);
    const [showModal, toggleModal] = useState(false);
    const [selectedProject, selectProject] = useState(
        {} as Project | undefined
    );
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortColumn, setSortColumn] = useState("createdDate");
    const [sortDescending, setSortDescending] = useState(true);
    const [totalSize, setTotalSize] = useState(undefined as number | undefined);
    const [currFilters, setFilters] = useState(
        null as { [key: string]: { [key: string]: any } } | null
    );

    React.useEffect(() => {
        populateProjects();
    }, [pageNumber, pageSize, currFilters, sortColumn, sortDescending]);

    const rows = projects.map((p) => ({
        ...p,
        country: p.location ? p.location.country : "",
        createdDate: new Date(p.createdDate).toLocaleString(),
        ...p.account,
    }));

    const queryParameters = QueryString.parse(window.location.search);

    const columns = [
        {
            dataField: "projectId",
            text: "Project ID",
            filter: textFilter({
                defaultValue: queryParameters && queryParameters.postId,
            }),
            headerStyle: { width: "80px" },
            formatter: (id: string) => {
                return (
                    <div
                        style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {id}
                    </div>
                );
            },
        },
        {
            dataField: "createdDate",
            text: "Date",
            // filter: dateFilter({
            //   style: {
            //     minWidth: "290px"
            //   }
            // }),
            sort: true,
        },
        {
            dataField: "userName",
            text: "Project Owner",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.userName,
                style: {
                    minWidth: "150px",
                },
            }),
        },
        {
            dataField: "projectName",
            text: "Title",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.message,
                style: {
                    minWidth: "150px",
                },
            }),
        },
        {
            dataField: "tags",
            text: "Tags",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.tags,
                style: {
                    minWidth: "150px",
                },
            }),
            formatter: tagsFormatter,
        },
        {
            dataField: "topics",
            text: "Topics",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.topics,
                style: {
                    minWidth: "150px",
                },
            }),
            formatter: topicsFormatter,
        },
        {
            dataField: "projectStatus",
            text: "Status",
            filter: selectFilter({
                delay: 300,
                options: {
                    active: ProjectStatus.Active,
                    removed: ProjectStatus.Removed,
                },
                placeholder: " ",
                style: {
                    maxWidth: "100px",
                },
            }),
        },
    ];

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            e.preventDefault();
            selectProject(projects.find((p) => p.projectId === row.projectId));
            toggleModal(true);
        },
    };

    return (
        <div style={{ width: "100%" }}>
            <h4>Circles</h4>
            <BootstrapTable
                remote={{
                    pagination: true,
                    filter: true,
                }}
                pagination={paginationFactory({
                    page: pageNumber,
                    sizePerPage: pageSize,
                    sizePerPageList: [10, 20, 50, 100],
                    totalSize: totalSize,
                    showTotal: false,
                    onPageChange: (page) => setPageNumber(page),
                    onSizePerPageChange: (sizePerPage) =>
                        setPageSize(sizePerPage),
                })}
                filter={filterFactory()}
                onTableChange={onTableChange}
                bootstrap4={true}
                keyField="projectId"
                data={rows}
                columns={columns}
                striped={true}
                bordered={false}
                rowEvents={rowEvents}
                headerClasses="posts-table__header"
                bodyClasses="posts-table__body"
                hover={true}
                defaultSorted={[{ dataField: "postedDate", order: "desc" }]}
                filterPosition={"top"}
            />
            {showModal && (
                <Backdrop backdropClickHandler={backdropClickHandler} />
            )}
            {showModal && (
                <ProjectModal
                    project={selectedProject}
                    closeModal={backdropClickHandler}
                    onChange={projectChanged}
                />
            )}
        </div>
    );

    async function populateProjects(): Promise<void> {
        try {
            const url =
                "api/projects" +
                createFilterQuery(
                    currFilters,
                    pageNumber,
                    pageSize,
                    sortColumn,
                    sortDescending
                );
            const response = await apiClient.get<PagedResponse<Project[]>>(url);
            updateState(response);
        } catch (e) {
            console.log(e);
        }
    }

    function projectChanged(updatedProject: Project) {
        const index = projects.findIndex(
            (p) => p.projectId === updatedProject.projectId
        );
        projects[index] = {
            ...projects[index],
            ..._.omitBy(updatedProject, _.isNull),
        };
    }

    function updateState(response: PagedResponse<Project[]>): void {
        setProjects(response.data);
        setTotalSize(response.totalSize);
    }

    function backdropClickHandler(): void {
        toggleModal(false);
    }

    function onTableChange(type: any, newState: any): void {
        const { filters, sortField, sortOrder } = newState;
        setSortColumn(sortField);
        setSortDescending(sortOrder === "desc");
        setFilters(filters);
    }
};
