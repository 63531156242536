import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './ReportsModal.module.css';
import {Button} from "react-bootstrap";
import {WebApiClient} from "../../../common/webApiClient";
import TextareaAutosize from "react-textarea-autosize";
import {Report} from "../../../models/Report";
import {InputGroup, InputGroupAddon} from "reactstrap";
import {ClickableLog, ClickableUserName} from "../../LayoutComponents/ClickableLinks/ClickableInternalLinks";
import {LogView} from "../ALogs/LogView";
import {AccountView} from "../Accounts/AccountView";

type PostModalProps = {
    report: Report | undefined,
    closeModal: () => void,
};

export const ReportsModal: React.FC<PostModalProps> = ({report, closeModal}) => {
    const apiClient = WebApiClient();
    const [error, setError] = useState("");
    const [adminNote, setAdminNote] = useState(report?.adminNote);
    const [relatedReports, setRelatedReports] = useState([] as Report[]);

    useEffect(() => {
        if (report?.post) {
            apiClient.get<Report[]>('api/reports/post/' + report.post.logId + '/related')
                .then(response => {
                    setRelatedReports(response);
                }).catch(e => {
                    setError(e.message);
            })
        }
        if (report?.account) {
            apiClient.get<Report[]>('api/reports/account/' + report.account.accountId + '/related')
                .then(response => {
                    setRelatedReports(response);
                }).catch(e => {
                setError(e.message);
            })
        }
    }, [report?.post, report?.account])

    const closeModalButton = () => (
        <FontAwesomeIcon className={styles.closeButton} icon="times" onClick={closeModal}/>
    );

    const reportSummary = (report: Report, key?: number) => {
        const postTitle = report.post?.title ?? "";

        return (
            <div className={styles.report} key={key}>
                {postTitle && <div className={styles.report_title}>Post: {postTitle}</div>}
                {report.account && <div className={styles.report_title}>Account: {report.account?.userName}</div>}
                <div className={styles.name_wrapper}>
                    <div>Reported by: {ClickableUserName(report.reportedByAccount.userName)}</div>
                    <div className={styles.postTime}>{new Date(report.reportedDate).toLocaleString()}</div>
                </div>
                <div>Reason: {report.message}</div>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>Reported {report?.reportType}</div>
            <div className={styles.reported_by}>
                <div>Reported by {ClickableUserName(report?.reportedByAccount?.userName)}</div>
                <div>Reason: {report?.message}</div>
                {report && <div>Reported at: {new Date(report.reportedDate).toLocaleString()}</div>}
            </div>
            {report?.post && <LogView log={report.post} />}
            {report?.post && <div>Go to log: {ClickableLog(report.post.logId, report.post.logId)}</div>}
            {report?.account && <AccountView account={report.account} />}
            {report?.account && <div>Go to account: {ClickableUserName(report.account.userName)}</div>}
            {error && <div style={{color: "red"}}>{error}</div>}
            <InputGroup style={{marginTop: "25px"}}>
                <TextareaAutosize className={"form-control"} value={adminNote}
                                  onChange={(e) => setAdminNote(e.target.value)}/>
                <InputGroupAddon addonType={"append"}>
                    <Button variant={"primary"} onClick={updateNote}>Update Note</Button>
                </InputGroupAddon>
            </InputGroup>
            <div className={styles.related_reports}>
                <div>Related reports</div>
                {relatedReports.map((r, i) => {
                    return reportSummary(r, i)
                })}
            </div>
            {closeModalButton()}
        </div>
    );

    function updateNote() {
        const data = {message: adminNote}
        apiClient.put<Report>("api/reports/" + report?.post?.logId + "/note", data).then((t) => {
            closeModal();
        }).catch(e => {
            setError(e.status + " " + e.statusText)
            e.json().then((message: any) => {
                setError(e.status + " " + e.statusText + ": " + message);
            })
            console.log(error)
        });
    }
};
