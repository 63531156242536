import React, {useEffect, useState} from "react";
import styles from './LegalDocumentsEditor.module.css';
import {LegalDocumentTypes} from "../../../common/constants";
import {WebApiClient} from "../../../common/webApiClient";
import {Button, Input} from "reactstrap";
import {LegalDocument, Versions} from "../../../models/LegalDocuments";
import ReactMarkdown from "react-markdown";

export const LegalDocumentsEditor: React.FC = () => {
    const apiClient = WebApiClient();
    const [url, setUrl] = useState("/api/legal/terms-and-conditions");
    const [type, setType] = useState(LegalDocumentTypes.TermsAndConditions)
    const [currentVersion, setCurrentVersion] = useState<string | undefined>(undefined);
    const [versions, setVersions] = useState<string[] | undefined>(undefined);
    const [document, setDocument] = useState<LegalDocument | undefined>(undefined);
    const [content, setContent] = useState<string>("");
    const [style, setStyle] = useState<string>("");
    const [showCheat, setShowCheat] = useState(false);

    const [error, setError] = useState<string | undefined>(undefined);

    const getVersions = () => {
        apiClient.get<Versions>(url + "/versions").then(response => {
            setVersions(response.versions);
            if (response.versions.length > 0) {
                setCurrentVersion(response.versions[0]);
            } else {
                setCurrentVersion(undefined);
            }
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    };

    const submitChange = (type: string) => {
        const data = {
            content: content,
            style: style,
            versionType: type
        }
        apiClient.post(url, data).then(() => {
            getVersions();
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    };

    const containsTags = (text: string) => {
        const regex = RegExp(/<.+?>/);
        return regex.test(text);
    }

    useEffect(() => {
        if (type === LegalDocumentTypes.TermsAndConditions) {
            setUrl("/api/legal/terms-and-conditions");
        } else if (type === LegalDocumentTypes.PrivacyPolicy) {
            setUrl("/api/legal/privacy-policy")
        }
    }, [type]);

    useEffect(() => {
        getVersions();
    }, [url]);

    useEffect(() => {
        if (!url || !currentVersion) {
            setDocument(undefined);
            return;
        }
        apiClient.get<LegalDocument>(url + "?version=" + currentVersion).then(response => {
            setDocument(response);
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    }, [url, currentVersion]);

    useEffect(() => {
        if (document) {
            setContent(document.content);
            setStyle(document.style);
        } else {
            setContent("");
            setStyle("");
        }
    }, [document]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Input id={styles.doctype} className={styles.dropdown} type={"select"} onChange={(e) => setType(e.target.value)}>
                    <option value={LegalDocumentTypes.TermsAndConditions}>{LegalDocumentTypes.TermsAndConditions}</option>
                    <option value={LegalDocumentTypes.PrivacyPolicy}>{LegalDocumentTypes.PrivacyPolicy}</option>
                </Input>
                <Input id={styles.version} className={styles.dropdown} type={"select"} onChange={(e) => setCurrentVersion(e.target.value)}>
                    {versions?.map(ver =>
                        <option value={ver}>{ver}</option>
                    )}
                </Input>
                {error && <div id={styles.error}>{error}</div>}
            </div>
            <div className={styles.second_header}>
                <div className={styles.cheat}>
                    <Button id={styles.cheat_button} className={styles.button} color={"danger"} onClick={() => setShowCheat(!showCheat)}>{showCheat ? "Hide cheat sheet" : "Show cheat sheet"}</Button>
                    {showCheat && <div>
                        <table className={styles.cheat_table}>
                            <thead>
                                <tr className={styles.header_row}>
                                    <th>Type</th><th>To get</th>
                                </tr>
                            </thead>
                            <tr className={styles.odd_row}>
                                <th>*Italic*</th><th><em>Italic</em></th>
                            </tr>
                            <tr className={styles.even_row}>
                                <th>**Bold**</th><th><strong>Bold</strong></th>
                            </tr>
                            <tr className={styles.odd_row}>
                                <th># Heading 1</th><th><h1>Heading 1</h1></th>
                            </tr>
                            <tr className={styles.even_row}>
                                <th>## Heading 2<br/>### Heading 3<br/>#### Heading 4</th><th><h2>Heading 2 etc.</h2></th>
                            </tr>
                            <tr className={styles.odd_row}>
                                <th>[Link](http://example.com)</th><th><a href={"http://example.com"}>Link</a></th>
                            </tr>
                            <tr className={styles.even_row}>
                                <th>![image](https://commonmark.org/help/images/favicon.png)</th><th><img src={"https://commonmark.org/help/images/favicon.png"} alt={"example icon"}/></th>
                            </tr>
                            <tr className={styles.odd_row}>
                                <th> Blockquote</th><th><blockquote>Blockquote</blockquote></th>
                            </tr>
                            <tr className={styles.even_row}>
                                <th>* List <br/>* List</th><th><ul><li>List</li><li>List</li></ul></th>
                            </tr>
                            <tr className={styles.odd_row}>
                                <th>1. One<br/>2. Two</th><th><ol><li>One</li><li>Two</li></ol></th>
                            </tr>
                            <tr className={styles.even_row}>
                                <th>---</th><th><hr/></th>
                            </tr>
                        </table>
                    </div>}
                </div>
                <div className={styles.buttons}>
                    <Button color={"success"} id={styles.minor} className={styles.button} disabled={(content === document?.content && style === document?.style) || containsTags(style)} onClick={() => submitChange("Minor")}>Submit new minor version</Button>
                    <Button color={"primary"} id={styles.major} className={styles.button} disabled={content === document?.content || containsTags(style)} onClick={() => submitChange("Major")}>Submit new major version</Button>
                </div>
            </div>
            <div className={styles.editor}>
                <div className={styles.editor_left}>
                    <textarea className={styles.content} onChange={(e) => setContent(e.target.value)} value={content}>
                        {content}
                    </textarea>
                </div>
                <div className={styles.editor_right}>
                    <ReactMarkdown className={styles.markdown} children={content} />
                </div>
            </div>
        </div>
    )
}