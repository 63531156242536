import React from "react";
import {Link} from "react-router-dom";

export const ClickableUserName = (userName?: string) => {
    return (
        <Link to={'/accounts?userName=' + userName} style={{color: "black"}}>{userName}</Link>
    )
}

export const ClickableLog = (display: string, postId?: string) => {
    return (
        <Link to={'/logs?logId=' + postId} style={{color: "black"}}>{display}</Link>
    )
}

export const ClickableUserLogs = (text: string, author?: string) => {
    return (
        <Link to={'/logs?userName=' + author} style={{color: "black"}}>{text}</Link>
    )
}