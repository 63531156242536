import React, {FormEvent, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Form, FormGroup} from "react-bootstrap";
import styles from './FAQModal.module.css';
import {Input, Label} from "reactstrap";
import TextareaAutosize from 'react-textarea-autosize';

type FeedbackQAModalProps = {
    originalQuestion?: string,
    originalAnswer?: string,
    type: string,
    error: string | null,
    closeModal: () => void,
    onSubmit: (e: FormEvent, newQuestion?: string, newAnswer?: string) => void,
    onDelete?: (e: FormEvent) => void,
};

export const FAQModal: React.FC<FeedbackQAModalProps> = ({originalQuestion, originalAnswer, type, error, closeModal, onSubmit, onDelete}) => {
    const [question, setQuestion] = useState(originalQuestion);
    const [sureDelete, setSureDelete] = useState(false);
    const [answer, setAnswer] = useState(originalAnswer);

    const closeModalButton = () => (
        <FontAwesomeIcon className={styles.closeButton} icon="times" onClick={closeModal} />
    );

    const upperCaseType = type.charAt(0).toUpperCase() + type.slice(1);

    const dangerButtonPress = (e: FormEvent) => {
        if (onDelete) {
            if (sureDelete) {
                onDelete(e);
            } else {
                setSureDelete(true);
                setTimeout(() => {
                    setSureDelete(false);
                }, 2000);
            }
        }
    }

    return (
        <div className={styles.container}>
            <h6>{upperCaseType} FAQ</h6>
            <Form onSubmit={(e: React.FormEvent) => onSubmit(e, question, answer)} style={{marginTop: "15px"}}>
                <FormGroup>
                    <Label>Question</Label>
                    <Input type={"text"} value={question} onChange={(e) => setQuestion(e.target.value)}/>
                    <Label style={{marginTop: "12px"}}>Answer</Label>
                    <TextareaAutosize className={"form-control"} value={answer} onChange={(e) => setAnswer(e.target.value)}/>
                    {error ? <div style={{color: "red"}}>{error}</div> : null}
                    <Button variant={"primary"} type={"submit"} style={{marginTop: "15px"}}>
                        {upperCaseType}
                    </Button>
                    {onDelete &&
                    <Button variant={"danger"} style={{marginLeft: "15px", marginTop: "15px"}} onClick={dangerButtonPress}>
                        {sureDelete ? "Are you sure?" : "Delete"}
                    </Button>}
                </FormGroup>
            </Form>
            {closeModalButton()}
        </div>
    );
}
