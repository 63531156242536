import React, {useState} from "react";
import {WebApiClient} from "../../../common/webApiClient";
import {Subscriber} from "../../../models/Subscriber";
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {PagedResponse} from "../../../models/PagedResponse";
import styles from './subscribersTable.module.css';
import {Button} from "react-bootstrap";
import Backdrop from "../../LayoutComponents/Backdrop/Backdrop";
import {SubscriberModal} from "./SubscriberModal";
import {createFilterQuery} from "../../../common/createFilterQuery";

export const SubscribersTable: React.FC = () => {
    const apiClient = WebApiClient();
    const [error, setError] = useState(null as string | null);
    const [subscribers, setSubscribers] = useState([] as Subscriber[]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortColumn, setSortColumn] = useState("signUpDate");
    const [sortDescending, setSortDescending] = useState(true);
    const [totalSize, setTotalSize] = useState(undefined as number | undefined);
    const [currFilters, setFilters] = useState(null as {[key: string]: {[key:string]: any}} | null);

    const [showModal, setShowModal] = useState(false);
    const [selectedSubscriber, setSelectedSubscriber] = useState(undefined as Subscriber | undefined);

    const [allCopied, setAllCopied] = useState(false);
    const [filteredCopied, setFilteredCopied] = useState(false);

    React.useEffect(() => { populateSubscribers() }, [pageNumber, pageSize, currFilters, sortColumn, sortDescending]);

    const rows = subscribers.map(s => ({
        ...s.survey,
        surveyDate: s.survey && new Date(s.survey.surveyDate).toLocaleString(),
        ...s,
        signUpDate: new Date(s.signUpDate).toLocaleString(),
    }));

    const columns = [{
        dataField: 'id',
        text: 'Subscriber Id',
        hidden: true,
    }, {
        dataField: 'signUpDate',
        text: 'Signup Date',
        sort: true,
    }, {
        dataField: 'name',
        text: 'Name',
        filter: textFilter({
            delay: 300,
            style: {
                minWidth: "100px",
            }
        })
    }, {
        dataField: 'email',
        text: 'Email',
        filter: textFilter({
            delay: 300,
            style: {
                minWidth: "100px",
            }
        })
    }, {
        dataField: 'surveyDate',
        text: 'Survey Date',
        sort: true,
    }, {
        dataField: 'mobileOs',
        text: 'Mobile Os',
        filter: selectFilter({
            delay: 300,
            options: {iOS: 'iOS', android: 'Android'},
            placeholder: ' ',
        })
    }, {
        dataField: 'podcastRelationship',
        text: 'Podcast Relationship',
        filter: textFilter({
            delay: 300,
            style: {
                minWidth: "100px",
            }
        })
    }, {
        dataField: 'topics',
        text: 'Topics',
        headerStyle: () => {
            return {width: '250px'};
        },
        filter: textFilter({
            delay: 300,
            style: {
                minWidth: "150px"
            }
        }),
        formatter: topicsFormatter
    }, {
        dataField: 'recordedPodcast',
        text: 'Recorded Podcast?',
        filter: selectFilter({
            delay: 300,
            options: {yes: 'yes', no: 'no'},
            placeholder: ' ',
        })
    }, {
        dataField: 'betterExperience',
        text: 'Better Experience',
        filter: textFilter({
            delay: 300,
            style: {
                minWidth: "100px",
            }
        })
    }, {
        dataField: 'whyNot',
        text: 'Why Not?',
        filter: textFilter({
            delay: 300,
            style: {
                minWidth: "100px",
            }
        })
    }, {
        dataField: 'hasAccount',
        text: 'Has Account',
    }];

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            e.preventDefault();
            setSelectedSubscriber(subscribers.find(s => s.id === row.id));
            setShowModal(true);
        }
    };

    return (
        <div style={{width: "100%"}}>
            <h4>Subscribers and Surveys</h4>
            {error && <div style={{color: "red"}}>{error}</div>}
            <Button variant={"primary"} onClick={() => getEmails(false, () => {setAllCopied(true); setTimeout(() => {setAllCopied(false)}, 3000)})} disabled={allCopied} style={{marginRight: "20px"}}>{allCopied ? "All emails copied!" : "Get all emails"}</Button>
            <Button variant={"secondary"} onClick={() => getEmails(true, () => {setFilteredCopied(true); setTimeout(() => {setFilteredCopied(false)}, 3000)})} disabled={filteredCopied}>{filteredCopied ? "Filtered emails copied!" : "Get filtered emails"}</Button>
            <BootstrapTable
                remote={{
                    pagination: true,
                    filter: true,
                }}
                pagination={paginationFactory({
                    page: pageNumber,
                    sizePerPage: pageSize,
                    sizePerPageList: [10, 20, 50, 100],
                    totalSize: totalSize,
                    showTotal: false,
                    onPageChange: (page) => setPageNumber(page),
                    onSizePerPageChange: (sizePerPage) => setPageSize(sizePerPage),
                })}
                filter={filterFactory()}
                onTableChange={onTableChange}
                bootstrap4={true}
                keyField='accountId'
                data={rows}
                columns={columns}
                striped={true}
                bordered={false}
                rowEvents={rowEvents}
                headerClasses={"posts-table__header"}
                bodyClasses={"posts-table__body"}
                hover={true}
                defaultSorted={[{dataField: "signUpDate", order: "desc"}]}
                filterPosition={"top"}
            />
            {showModal && <Backdrop backdropClickHandler={backdropClickHandler} />}
            {showModal && <SubscriberModal subscriber={selectedSubscriber} closeModal={backdropClickHandler}/>}
        </div>
    )

    function backdropClickHandler(): void {
        setShowModal(false);
    }

    function getEmails(filter: boolean, callback: () => void) {
        const filterQuery = filter ? createFilterQuery(currFilters) : ""
        apiClient.get<string[]>('/api/subscribers/emails' + filterQuery).then(emails => {
            const el = document.createElement("textarea");
            el.value = emails.join("; ");
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            callback();
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    }

    function topicsFormatter(topics: string[], row: any, rowIndex: number, formatExtraData: any): JSX.Element {
        if (!topics) {
            topics = []
        }
        return (
            <div className={styles.topicsContainer}>
                {topics.filter(filterTopics).map((topic, i) => (
                    <div key={i} style={{padding: "1px 3px", borderRadius: "3px", color: "white", backgroundColor: "teal", whiteSpace: "nowrap", zIndex: "inherit"}}>
                        {topic}
                    </div>
                ))}
            </div>
        );
    }

    function filterTopics(topic: string) {
        if (!currFilters || !currFilters['topics']) return true;
        const topicFilters: string[] = currFilters['topics'].filterVal.split(',');
        for (const topFilt of topicFilters) {
            if (topic.toLowerCase().includes(topFilt.trim().toLowerCase())) {
                return true;
            }
        }
        return false;
    }

    async function populateSubscribers() {
        try {
            const url = 'api/subscribers' + createFilterQuery(currFilters, pageNumber, pageSize, sortColumn, sortDescending);
            console.log(url);
            const response = await apiClient.get<PagedResponse<Subscriber[]>>(url);
            updateState(response)
        } catch(e: any) {
            setError(e.message);
            console.log(e.message);
        }
    }


    function updateState(response: PagedResponse<Subscriber[]>): void {
        setSubscribers(response.data);
        setTotalSize(response.totalSize);
    }

    function onTableChange(type: any, newState: any): void {
        const {filters, sortField, sortOrder} = newState;
        setFilters(filters);
        setSortColumn(sortField);
        setSortDescending(sortOrder === "desc");
    }
}