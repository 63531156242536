import React, {CSSProperties} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

type SideDrawerProps = {
  icon: IconProp,
  name: string,
}

const sideDrawerItem: React.FC<SideDrawerProps> = ({ icon, name }) => {

  const iconStyle: CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    margin: "0 12px",
    width: "22px",
    height: "22px",
  };

  return (
    <div>
      <div style={iconStyle}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div>
        {name}
      </div>
    </div>
  );
};

export default sideDrawerItem;