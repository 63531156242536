import React from 'react';
import {useDrop} from 'react-dnd';
import './BoardList.css'

interface BoardListProps {
  type: string,
  title: string,
  status: string,
}

export const BoardList: React.FC<BoardListProps> = ({ children, type, title, status }) => {
  const [{ isOver }, drop] = useDrop({
    accept: type,
    drop: () => ({ status }),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    })
  });

  return (
    <div className="board-list__wrapper">
      <div 
        ref={drop} 
        className="board-list"
        style={{ 
          backgroundColor: isOver ? '#c5c5c7' : '#ebecf0' 
        }}
      >
          <div className="board-list__header">{title}</div>
          {children}
      </div>
    </div>
  );
}


