import React, {useState} from "react";
import {WebApiClient} from "../../../common/webApiClient";
import {AccountProfileFull} from "../../../models/AccountProfile";
import {createFilterQuery} from "../../../common/createFilterQuery";
import {PagedResponse} from "../../../models/PagedResponse";
import {AdminAccount} from "../../../models/AdminAccount";
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Backdrop from "../../LayoutComponents/Backdrop/Backdrop";
import {AccountModal} from "../../UserContentPages/Accounts/AccountModal";
import {AddAccountPanel} from "./AddAccountPanel";
import {AdminAccountModal} from "./AdminAccountModal";


export const AdminAccountsTable: React.FC = () => {
    const apiClient = WebApiClient();
    const [accounts, setAccounts] = useState([] as AdminAccount[]);
    const [showModal, toggleModal] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState({} as AdminAccount | undefined);
    const [sortColumn, setSortColumn] = useState("username");
    const [sortDescending, setSortDescending] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalSize, setTotalSize] = useState(undefined as number | undefined);
    React.useEffect(() => { populateAccounts() }, [pageNumber, pageSize, sortColumn, sortDescending]);

    const rows = accounts.map(a => ({
        ...a,
    }));

    const columns = [{
        dataField: 'adminAccountId',
        text: 'Account Id',
        hidden: true,
    }, {
        dataField: 'username',
        text: 'Username',
        sort: true,
    }, {
        dataField: 'email',
        text: 'Email',
        sort: true,
    }, {
        dataField: 'type',
        text: "Type",
        sort: true,
    }, {
        dataField: 'status',
        text: "Status",
        sort: true,
    }];

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            e.preventDefault();
            setSelectedAccount(accounts.find(a => a.adminAccountId === row.adminAccountId));
            toggleModal(true);
        }
    };

    return (
        <div style={{width: "100%", overflowY: "scroll"}}>
            <h4>Admin accounts</h4>
            <AddAccountPanel populateAccounts={populateAccounts}/>
            <BootstrapTable
                remote={{
                    pagination: true,
                    sort: true,
                    filter: true,
                }}
                pagination={paginationFactory({
                    page: pageNumber,
                    sizePerPage: pageSize,
                    sizePerPageList: [10, 20, 50, 100],
                    totalSize: totalSize,
                    showTotal: false,
                    onPageChange: (page) => setPageNumber(page),
                    onSizePerPageChange: (sizePerPage) => setPageSize(sizePerPage),
                })}
                filter={filterFactory()}
                onTableChange={onTableChange}
                bootstrap4={true}
                keyField='accountId'
                data={rows}
                columns={columns}
                rowEvents={rowEvents}
                striped={true}
                bordered={false}
                headerClasses={"posts-table__header"}
                bodyClasses={"posts-table__body"}
                hover={true}
                defaultSorted={[{dataField: "username", order: "desc"}]}
                filterPosition={"top"}
            />
            {showModal && <Backdrop backdropClickHandler={backdropClickHandler} />}
            {showModal && selectedAccount && <AdminAccountModal account={selectedAccount} closeModal={backdropClickHandler} onChange={populateAccounts}/>}
        </div>
    )

    async function populateAccounts() {
        try {
            const url = 'api/system-accounts' + createFilterQuery(null, pageNumber, pageSize, sortColumn, sortDescending);
            console.log(url);
            const response = await apiClient.get<PagedResponse<AdminAccount[]>>(url);
            updateState(response)
        } catch(e: any) {
            console.log(e.message);
        }
    }

    function backdropClickHandler(): void {
        toggleModal(false);
    }

    function updateState(response: PagedResponse<AdminAccount[]>): void {
        setAccounts(response.data);
        setTotalSize(response.totalSize);
    }

    function onTableChange(type: any, newState: any): void {
        const {sortField, sortOrder} = newState;
        setSortColumn(sortField);
        setSortDescending(sortOrder === "desc");
    }
}