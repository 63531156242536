import React from "react";
import {UncontrolledTooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface TooltipElementProps {
    tooltip: string,
}

export const TooltipElement: React.FC<TooltipElementProps> = (props) => {
    return (
        <span>
            <FontAwesomeIcon icon={"question-circle"} id={"tooltip-q"}/>
            <UncontrolledTooltip
                placement={"top"}
                target={"tooltip-q"}>
                {props.tooltip}
            </UncontrolledTooltip>
        </span>
    )
}