import * as React from "react";
import './Dashboard.css'
import {useEffect, useState} from "react";
import {useInterval} from "../../../common/useInterval";
import {WebApiClient} from "../../../common/webApiClient";
import {isOshi} from "../../../common/appName";
import {AnalyticsResponse} from "../../../models/AnalyticsResponse";
import {Area, AreaChart, XAxis, YAxis} from "recharts";

export const Dashboard = () => {
    const [analytics, setAnalytics] = useState<undefined | AnalyticsResponse>(undefined);
    const [dates, setDates] = useState<string[]>([]);
    const [accountData, setAccountData] = useState<{x: string, y: number}[]>([]);
    const [logData, setLogData] = useState<{x: string, y: number}[]>([]);
    const [error, setError] = useState<string | undefined>(undefined);
		const isOshiApp = isOshi();
    useEffect(() => {
        updateAnalytics();
        recalculateDates();
    }, [])

    useInterval(() => {
        recalculateDates();
        updateAnalytics();
    }, 15000);

    useEffect(() => {
        if (dates && analytics) {
            let ad = [];
            let ld = [];
            for (let i=0; i<8; i++) {
                ad.push({x: dates[i], y: analytics.accountsThisWeek[i]})
                ld.push({x: dates[i], y: analytics.logsThisWeek[i]})
            }
            setAccountData(ad);
            setLogData(ld);
        }
    }, [analytics, dates]);

    return (
        <div className={"dashboard-container"}>
            <div className={"dashboard-title"}>{isOshiApp ? 'Oshi' : 'Logcast'} Analytics</div>
            <div className="stat-main-container">
                <div className={"half"} id={"left-side"}>
                    <div className={"stat-container"}>
                        <div className={"stat-title"}>Total Accounts</div>
                        <div className={"stat-text"}>{analytics?.numberOfAccounts ?? 0}</div>
                    </div>
                    <div className={"stat-container"}>
                        <div className={"stat-title"}>New Accounts Last 24H</div>
                        <div className={"stat-text"}>{analytics?.numberOfAccountsLast24Hours ?? 0}</div>
                    </div>
                    <div className={"stat-container"}>
                        <div className={"stat-title"}>New Accounts This Week</div>
                        <AreaChart width={500} height={300} data={accountData} className={"stat-chart"} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                            <XAxis dataKey={"x"} />
                            <YAxis/>
                            <Area type={"monotone"} dataKey={"y"} stroke={"black"} strokeWidth={2} fill={"#f4134a"} fillOpacity={1}/>
                        </AreaChart>
                    </div>
                </div>
                <div className={"half"} id={"right-side"}>
                    <div className={"stat-container"}>
                        <div className={"stat-title"}>Total Logs</div>
                        <div className={"stat-text"}>{analytics?.numberOfLogs ?? 0}</div>
                    </div>
                    <div className={"stat-container"}>
                        <div className={"stat-title"}>New Logs Last 24H</div>
                        <div className={"stat-text"}>{analytics?.numberOfLogsLast24Hours ?? 0}</div>
                    </div>
                    <div className={"stat-container"}>
                        <div className={"stat-title"}>New Logs This Week</div>
                        <AreaChart width={500} height={300} data={logData} className={"stat-chart"} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                            <XAxis dataKey={"x"}/>
                            <YAxis/>
                            <Area type={"monotone"} dataKey={"y"} stroke={"black"} strokeWidth={2} fill={"#f4134a"} fillOpacity={1}/>
                        </AreaChart>
                    </div>
                </div>
            </div>
        </div>
    );

    function recalculateDates() {
        let dates = [];
        for (let i=0; i<8; i++) {
            const usedDate = new Date(Date.now() - i * (24 * 60 * 60 * 1000));
            dates.push(usedDate);
        }

        dates = dates.reverse();

        const datesAsText = dates.map(d => {return d.getDate() + "/" + (d.getMonth() + 1)});
        setDates(datesAsText);
    }

    function updateAnalytics() {
        const apiClient = WebApiClient();

        apiClient.get<AnalyticsResponse>('/api/analytics')
            .then(response => {
                setAnalytics(response);
            })
            .catch((e: Error) => {
                setError(e.message)
                console.log(e.message)
            });
    }
};