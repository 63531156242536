import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { WebApiClient } from "../../../common/webApiClient";
import { NotificationFrequency, NotificationTarget, ProgrammedNotification } from "../../../models/ProgrammedNotification";
import Backdrop from "../../LayoutComponents/Backdrop/Backdrop";
import styles from './Notifications.module.css';

export const NotificationsTable: React.FC = () => {
    const apiClient = WebApiClient();
    const [notifications, setNotifications] = useState([] as ProgrammedNotification[]);
    const [selectedNotif, setNotification] = useState({} as any | undefined);
    const [totalSize, setTotalSize] = useState(undefined as number | undefined);
    const [showModal, toggleModal] = useState(false);
		const [error, setError] = useState("");
		const daysOfWeek: {[x:string]: string} = {
			'0': 'Monday',
			'1': 'Tuesday',
			'2': 'Wednesday',
			'3': 'Thursday',
			'4': 'Friday',
			'5': 'Saturday',
			'6': 'Sunday',
		}

    React.useEffect(() => {
        let active = true;

        const updateLogs = async () => {
            try {
                const url =
                    "api/programmed-notifications";
                const response = await apiClient.get<ProgrammedNotification[]>(url);
                if (active) {
                    updateState(response.map(n=> ({
											...n,
											createdDate: new Date(n.createdDate).toLocaleString()
										})));
                }
            } catch (e) {
                console.log(e);
            }
        };
        updateLogs();
        return () => {
            active = false;
        };
    }, []);

    const rows = notifications;

    const columns = [
        {
            dataField: "id",
            text: "ID",
            style: {
                textOverflow: "ellipsis",
                overflow: "hidden",
                'white-space': "nowrap",
            },
        },
        {
            dataField: "createdDate",
            text: "Date",
            headerStyle: { width: "160px" },
            sort: true,
        },
        {
            dataField: "frequency",
            text: "Type",
        },
        {
            dataField: "title",
            text: "Title",
        },
        {
            dataField: "text",
            text: "Text"
        },
        {
            dataField: "executionDaysOfWeek",
            text: "Days Of Week",
            formatter: (value:string)=> value.split(',').map(v=> daysOfWeek[v]).join(', '),
            headerStyle: { width: "110px" },
        },
        {
            dataField: "status",
            text: "Status",
        },
        {
            dataField: "target",
            text: "Target",
        },
        {
            dataField: "frequency",
            text: "Frequency",
        },
    ];

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            e.preventDefault();
            setNotification(notifications.find((p) => p.id === row.id));
        },
    };

		const handleDaysOfWeekChange = (target: HTMLElement) => {
			const e = target as HTMLSelectElement;
			let selectedItems = [] as string[];
			for (let i = 0; i < e.selectedOptions.length; i++) {
				const item = e.selectedOptions.item(i)
				if (item)
					selectedItems.push(item.value);
			}
			setNotification({...selectedNotif, executionDaysOfWeek: selectedItems.join(',')})
		}

		const createNotification = async (event: any)=>{
			event.preventDefault();
			try {
				const url =
						"api/programmed-notifications";
				const response = await apiClient.post<ProgrammedNotification>(url, selectedNotif);
				updateState([...notifications, response]);
			} catch (e) {
					console.log(e);
					setError((e as Error).message);
			}
		}
		const closeModalButton = () => (
			<FontAwesomeIcon style={{
				position: 'absolute', 
				top: '10px',
				right: '10px', 
				fontSize: '20px', 
				color: 'grey',
				cursor: 'pointer'
			}} icon="times" onClick={()=>toggleModal(false)} />
		);

    return (
        <div style={{ width: "100%", overflowY: "scroll" }}>
            <div className="d-flex justify-content-between">
							<h4>Programmed notifications</h4>
							<button className="btn btn-danger" onClick={()=> toggleModal(true)}>Add notification</button>
						</div>
            <BootstrapTable
                remote={{
                    pagination: true,
                    filter: true,
                }}
                pagination={paginationFactory({
                    page: 0,
                    sizePerPage: 10,
                    sizePerPageList: [10, 20, 50, 100],
                    totalSize: totalSize,
                    showTotal: true
                })}
                filter={filterFactory()}
                bootstrap4={true}
                keyField="id"
                data={rows}
                columns={columns}
                striped={true}
                bordered={false}
                rowEvents={rowEvents}
                headerClasses="posts-table__header"
                bodyClasses="posts-table__body"
                hover={true}
                defaultSorted={[{ dataField: "postedDate", order: "desc" }]}
                filterPosition={"top"}
            />
            {showModal && (
                <Backdrop backdropClickHandler={()=> toggleModal(false)} />
            )}
						{showModal && 
    <div className={styles.container}>
      {error && <div style={{color: "red"}}>{error}</div>}
      <Form style={{marginTop: "15px"}}>
        <FormGroup>
          <Label>Title</Label>
          <Input type={"text"} value={selectedNotif.title} onChange={(e) => setNotification({...selectedNotif, title: e.target.value})} />
        </FormGroup>
        <FormGroup>
          <Label>Text</Label>
          <Input type={"text"} value={selectedNotif.text} onChange={(e) => setNotification({...selectedNotif, text: e.target.value})} />
        </FormGroup>
        <FormGroup>
          <Label>Target</Label>
          <Input type={"select"} value={selectedNotif.target} onChange={(e) => setNotification({...selectedNotif, target: e.target.value})}>
            {Object.values(NotificationTarget).map((target, i) => (
                <option key={i} value={target}>{target}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Frequency</Label>
          <Input type={"select"} value={selectedNotif.frequency} onChange={(e) => setNotification({...selectedNotif, frequency: e.target.value})}>
            {Object.values(NotificationFrequency).map((target, i) => (
                <option key={i} value={target}>{target}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Days of the week</Label>
          <Input type={"select"} multiple value={selectedNotif.executionDaysOfWeek} onChange={e=> handleDaysOfWeekChange(e.target)}>
            {Object.keys(daysOfWeek).map((k) => (
                <option key={k} value={k}>{daysOfWeek[k]}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Execution date</Label>
					<Row>
						<Input type={"date"} value={selectedNotif.executionDate} onChange={(e) => setNotification({...selectedNotif, executionDate: e.target.value})}/>
						<Input type={"time"} value={selectedNotif.executionTime} onChange={(e) => setNotification({...selectedNotif, executionTime: e.target.value})}/>
					</Row>
        </FormGroup>
        <Button type={"submit"} onClick={(e) => createNotification(e)}>Create notification</Button>
      </Form>
      {closeModalButton()}
    </div>}
        </div>
    );

    function logChanged(updatedLog: ProgrammedNotification) {
        const index = notifications.findIndex((p) => p.id === updatedLog.id);
        notifications[index] = { ...notifications[index], ..._.omitBy(updatedLog, _.isNull) };
    }

    function updateState(response: ProgrammedNotification[]): void {
        setNotifications(response);
        setTotalSize(response.length);
    }
};
