import * as React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import Cookie from 'js-cookie';
import {Layout} from "./Layout";

export interface ProtectedRouteProps extends RouteProps {
    authenticationPath: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({...rest}) => {
    let redirectPath = '';
    if (!Cookie.get("signed_in")) {
        redirectPath = '/login';
    }
    if (redirectPath) {
        const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
        return <Route {...rest} component={renderComponent} render={undefined} />;
    } else {
        return (
            <Layout>
                <Route {...rest} />
            </Layout>);
    }
};