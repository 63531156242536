import React from "react";
import {useDrag, useDrop} from "react-dnd";
import {ItemTypes} from "../../../common/constants";
import './FAQ.css';

export interface DraggableOrderedCardProps {
    id: string,
    question: string,
    answer: string,
    openModal: (id: string) => void,
    moveCard: (id: string, to: number) => void,
    updateCardPosition: (id: string, to: number) => void,
    findCard: (id: string) => { index: number },
}

interface DragItem {
    type: string,
    id: string,
    originalIndex: number,
}

export const DraggableOrderedCard: React.FC<DraggableOrderedCardProps> = ({id, question, answer, openModal, moveCard, findCard, updateCardPosition}) => {
    const originalIndex = findCard(id).index

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.FeedbackQA, id, originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        end: (dropResult, monitor) => {
            const { id: droppedId, originalIndex } = monitor.getItem()
            const didDrop = monitor.didDrop()
            if (!didDrop) {
                moveCard(droppedId, originalIndex)
            } else {
                const { index: droppedIndex } = findCard(droppedId);
                updateCardPosition(droppedId, droppedIndex)
            }
        },
    })

    const [, drop] = useDrop({
        accept: ItemTypes.FeedbackQA,
        canDrop: () => false,
        hover({ id: draggedId }: DragItem ) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id)
                moveCard(draggedId, overIndex)
            }
        },
    })

    const opacity = isDragging ? 0 : 1

    return (
        <div className={"draggable-card"} ref={(node) => drag(drop(node))} style={{opacity}} onClick={() => openModal(id)}>
            <div className={"question"}>{question}</div>
            <div className={"answer"}>{answer}</div>
        </div>
    )
}