import * as React from 'react';
import logo from '../../../images/logcast_logo.png';
import './ResetPasswordPage.css';
import {ResetPasswordForm} from "./ResetPasswordForm";

export const ResetPasswordPage = () => {
    return (
        <div className="main">
            <div className="login-container">
                <a href={"/"}><img src={logo} style={{maxHeight: "55px"}} alt={"Logcast logo"}/></a>
                <div><ResetPasswordForm /></div>
            </div>
        </div>
    )
};