import React, {ChangeEvent, useState} from "react";
import styles from './TraceLogTable.module.css'
import filterFactory, {Comparator, dateFilter, textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import './TraceLogTable.css';
import {createFilterQuery} from "../../../common/createFilterQuery";
import {PagedResponse} from "../../../models/PagedResponse";
import {TraceLog} from "../../../models/TraceLog";
import {WebApiClient} from "../../../common/webApiClient";

export const TraceLogTable: React.FC = () => {
    const apiClient = WebApiClient();
    const [error, setError] = useState("");
    const [traceLogs, setTraceLogs] = useState([] as TraceLog[]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortColumn, setSortColumn] = useState("occurred");
    const [sortDescending, setSortDescending] = useState(true);
    const [totalSize, setTotalSize] = useState(undefined as number | undefined);
    const [currFilters, setFilters] = useState(null as {[key: string]: {[key:string]: any}} | null);
    const [grouping, setGrouping] = useState({} as {[key: string]: boolean});

    React.useEffect(() => {
        populateTraceLogs().then();
    }, [pageNumber, pageSize, currFilters, sortColumn, sortDescending, grouping]);

    const rows = traceLogs.map(t => ({
        ...t,
        occurred: new Date(t.occurred).toLocaleString(),
    }));

    // @ts-ignore
    // @ts-ignore
    const columns = [{
        dataField: 'traceLogId',
        text: 'Trace Log ID',
        hidden: true,
    }, {
        dataField: 'route',
        text: 'Route',
        filter: textFilter({
            delay: 500,
            style: {
                minWidth: "200px"
            }
        }),
        headerFormatter: groupHeader,
    }, {
        dataField: 'ipAddress',
        text: 'Ip Address',
        filter: textFilter({
            delay: 500,
            style: {
                minWidth: "150px"
            }
        }),
        headerFormatter: groupHeader,
    }, {
        dataField: 'accountId',
        text: 'Account Id',
        filter: textFilter({
            delay: 500,
            style: {
                minWidth: "150px"
            }
        }),
        headerFormatter: groupHeader,
    }, {
        dataField: 'count',
        text: 'Count',
        sort: true,
    }, {
        dataField: 'occurred',
        text: 'Occurred',
        sort: true,
        filter: dateFilter({
            delay: 500,
            comparators: [Comparator.LT, Comparator.GT],
            className: styles.date_box
        })
    }];

    return (
        <div>
            <div className={styles.error}>{error}</div>
            <BootstrapTable
                remote={{
                    pagination: true,
                    sort: true,
                    filter: true,
                }}
                pagination={paginationFactory({
                    page: pageNumber,
                    sizePerPage: pageSize,
                    sizePerPageList: [10, 20, 50, 100],
                    totalSize: totalSize,
                    showTotal: false,
                    onPageChange: (page) => setPageNumber(page),
                    onSizePerPageChange: (sizePerPage) => setPageSize(sizePerPage),
                })}
                filter={filterFactory()}
                onTableChange={onTableChange}
                bootstrap4={true}
                keyField='traceLogId'
                data={rows}
                columns={columns}
                striped={true}
                bordered={false}
                headerClasses={"traceLog-table__header"}
                bodyClasses={"traceLog-table__body"}
                hover={true}
                defaultSorted={[{dataField: "occurred", order: "desc"}]}
                filterPosition={"top"}
            />
        </div>
    )

    async function populateTraceLogs() {
        try {
            const groupQuery = Object.keys(grouping).map((groupingName) => {
                if (grouping[groupingName]) {
                    return "&grouping=" + groupingName;
                }
            }).join("")
            const url = 'api/tracelogs' + createFilterQuery(currFilters, pageNumber, pageSize, sortColumn, sortDescending) + groupQuery;
            console.log(url);
            const response = await apiClient.get<PagedResponse<TraceLog[]>>(url);
            updateState(response)
        } catch(e: any) {
            setError(e.message);
            console.log(e.message);
        }
    }

    function updateState(response: PagedResponse<TraceLog[]>): void {
        setTraceLogs(response.data);
        setTotalSize(response.totalSize);
    }

    function groupHeader(column: any, colIndex: number) {
        return (
            <div>
                {column.text}
                <div><input type={"checkbox"} name={column.dataField} onChange={onGroupingChange}/> Group</div>
            </div>
        )
    }

    function onGroupingChange(event: ChangeEvent<HTMLInputElement>) {
        let tmp = {...grouping};
        tmp[event.target.name] = event.target.checked;
        setGrouping(tmp);
    }

    function onTableChange(type: any, newState: any): void {
        const {filters, sortField, sortOrder} = newState;
        setFilters(filters);
        setSortColumn(sortField);
        setSortDescending(sortOrder === "desc");
    }
}
