import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
    selectFilter,
    textFilter,
} from "react-bootstrap-table2-filter";
import { WebApiClient } from "../../../common/webApiClient";
import { Log } from "../../../models/Log";
import { PagedResponse } from "../../../models/PagedResponse";
import Backdrop from "../../LayoutComponents/Backdrop/Backdrop";
import { LogModal } from "./LogModal";
import "./LogsTable.css";
import _ from "lodash";
import { LogStatus } from "../../../common/constants";
import { createFilterQuery } from "../../../common/createFilterQuery";
import * as QueryString from "query-string";
import { topicsFormatter } from "../../LayoutComponents/Tooltip/TopicsFormatter";
import { tagsFormatter } from "../../LayoutComponents/Tooltip/TagsFormatter";

export const LogsTable: React.FC = () => {
    const apiClient = WebApiClient();
    const [logs, setLogs] = useState([] as Log[]);
    const [showModal, toggleModal] = useState(false);
    const [selectedLog, selectLog] = useState({} as Log | undefined);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortColumn, setSortColumn] = useState("postedDate");
    const [sortDescending, setSortDescending] = useState(true);
    const [totalSize, setTotalSize] = useState(undefined as number | undefined);
    const [currFilters, setFilters] = useState(
        null as { [key: string]: { [key: string]: any } } | null
    );

    React.useEffect(() => {
        let active = true;

        const updateLogs = async () => {
            try {
                const url =
                    "api/logs" +
                    createFilterQuery(
                        currFilters,
                        pageNumber,
                        pageSize,
                        sortColumn,
                        sortDescending
                    );
                console.log(url);
                const response = await apiClient.get<PagedResponse<Log[]>>(url);
                if (active) {
                    updateState(response);
                }
            } catch (e) {
                console.log(e);
            }
        };
        updateLogs();
        return () => {
            active = false;
        };
    }, [pageNumber, pageSize, currFilters, sortColumn, sortDescending]);

    const rows = logs.map((p) => ({
        ...p,
        country: p.location ? p.location.name : "",
        postedDate: new Date(p.postedDate).toLocaleString(),
        numberOfLikes: p.likes.length,
        numberOfStreams:
            p.recordingFile == null
                ? "-"
                : p.recordingFile.totalNumberOfStreams,
    }));

    const queryParameters = QueryString.parse(window.location.search);

    const columns = [
        {
            dataField: "logId",
            text: "Log ID",
            filter: textFilter({
                defaultValue: queryParameters && queryParameters.logId,
            }),
            style: {
                textOverflow: "ellipsis",
                overflow: "hidden",
                "white-space": "nowrap",
            },
        },
        {
            dataField: "postedDate",
            text: "Date",
            headerStyle: { width: "160px" },
            sort: true,
        },
        {
            dataField: "userName",
            text: "Author",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.userName,
            }),
        },
        {
            dataField: "title",
            text: "Title",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.message,
            }),
        },
        {
            dataField: "tags",
            text: "Tags",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.tags,
            }),
            formatter: tagsFormatter,
        },
        {
            dataField: "topics",
            text: "Topics",
            filter: textFilter({
                delay: 300,
                defaultValue: queryParameters && queryParameters.topics,
            }),
            formatter: topicsFormatter,
        },
        {
            dataField: "numberOfLikes",
            text: "Likes",
            sort: true,
            headerStyle: { width: "80px" },
        },
        {
            dataField: "numberOfStreams",
            text: "Streams",
            sort: true,
            headerStyle: { width: "80px" },
        },
        {
            dataField: "isPaidLog",
            text: "Is Paid",
            filter: selectFilter({
                delay: 300,
                options: { true: "true", false: "false" },
                placeholder: " ",
            }),
            headerStyle: { width: "110px" },
        },
        {
            dataField: "isExcludedFromFeed",
            text: "Exclude from Feed",
            filter: selectFilter({
                delay: 300,
                options: { true: "true", false: "false" },
                placeholder: " ",
            }),
            headerStyle: { width: "110px" },
        },
        {
            dataField: "reportStatus",
            text: "Report status",
            headerStyle: { width: "110px" },
        },
        {
            dataField: "logStatus",
            text: "Log status",
            filter: selectFilter({
                delay: 300,
                options: {
                    active: LogStatus.Active,
                    removed: LogStatus.Removed,
                },
                placeholder: " ",
            }),
            headerStyle: { width: "110px" },
        },
    ];

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            e.preventDefault();
            selectLog(logs.find((p) => p.logId === row.logId));
            toggleModal(true);
        },
    };

    return (
        <div style={{ width: "100%", overflowY: "scroll" }}>
            <h4>Logs</h4>
            <BootstrapTable
                remote={{
                    pagination: true,
                    filter: true,
                }}
                pagination={paginationFactory({
                    page: pageNumber,
                    sizePerPage: pageSize,
                    sizePerPageList: [10, 20, 50, 100],
                    totalSize: totalSize,
                    showTotal: false,
                    onPageChange: (page) => setPageNumber(page),
                    onSizePerPageChange: (sizePerPage) =>
                        setPageSize(sizePerPage),
                })}
                filter={filterFactory()}
                onTableChange={onTableChange}
                bootstrap4={true}
                keyField="logId"
                data={rows}
                columns={columns}
                striped={true}
                bordered={false}
                rowEvents={rowEvents}
                headerClasses="posts-table__header"
                bodyClasses="posts-table__body"
                hover={true}
                defaultSorted={[{ dataField: "postedDate", order: "desc" }]}
                filterPosition={"top"}
            />
            {showModal && (
                <Backdrop backdropClickHandler={backdropClickHandler} />
            )}
            {showModal && (
                <LogModal
                    log={selectedLog}
                    closeModal={backdropClickHandler}
                    onChange={logChanged}
                />
            )}
        </div>
    );

    function logChanged(updatedLog: Log) {
        const index = logs.findIndex((p) => p.logId === updatedLog.logId);
        logs[index] = { ...logs[index], ..._.omitBy(updatedLog, _.isNull) };
    }

    function updateState(response: PagedResponse<Log[]>): void {
        setLogs(response.data);
        setTotalSize(response.totalSize);
    }

    function backdropClickHandler(): void {
        toggleModal(false);
    }

    function onTableChange(type: any, newState: any): void {
        const { filters, sortField, sortOrder } = newState;
        setSortColumn(sortField);
        setSortDescending(sortOrder === "desc");
        setFilters(filters);
    }
};
