import React, {useState} from "react";
import {Topic} from "../../../models/Topic";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Input, Label} from "reactstrap";
import {WebApiClient} from "../../../common/webApiClient";
import styles from './TopicModal.module.css';

type TopicModalProps = {
    topic: Topic | undefined,
    closeModal: () => void,
    onChange: (updatedTopic: Topic) => void,
    onDelete: (deletedTopicName: string) => void,
};


export const TopicModal: React.FC<TopicModalProps> = ({topic, closeModal, onChange, onDelete}) => {
    const apiClient = WebApiClient();
    const [newName, setNewName] = useState(topic?.name);
    const [sureDelete, setSureDelete] = useState(false);
    const [error, setError] = useState(null as string | null);

    const closeModalButton = () => (
        <FontAwesomeIcon className={styles.closeButton} icon="times" onClick={closeModal} />
    );

    return (
        <div className={styles.container}>
            <div>
                <Label>Topic</Label>
                <Input type={"text"} value={newName} onChange={(e) => setNewName(e.target.value)}/>
            </div>
            {error ? <div style={{color: "red"}}>{error}</div> : null}
            <Button onClick={updateTopic} disabled={newName?.length === 0 || newName?.trim() === "" || newName === topic?.name} style={{marginTop: "15px"}}>Update</Button>
            <Button variant={"danger"} onClick={deleteTopic} style={{marginLeft: "15px", marginTop: "15px"}}>{sureDelete ? "Are you sure?" : "Delete"}</Button>
            {closeModalButton()}
        </div>
    )

    function deleteTopic() {
        if (!sureDelete) {
            setSureDelete(true);
            setTimeout(() => {
                setSureDelete(false);
            }, 2000);
        } else {
            if (topic != null) {
                const data = {topic: topic.name}
                apiClient.delete("api/topics", data).then(() => {
                    onDelete(topic.name)
                    closeModal();
                }).catch((e: Error) => {
                    setError(e.message)
                    console.log(e.message)
                });
            }
        }
    }

    function updateTopic() {
        const data = {oldTopic: topic?.name, newTopic: newName}
        apiClient.put<Topic>("api/topics", data).then((t) => {
            onChange(t);
            closeModal();
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    }
}