import * as React from 'react';
import {Redirect} from 'react-router-dom';
import Cookie from 'js-cookie';

export const Landing = () => {
    return (
        Cookie.get("signed_in") ?
            <Redirect to="/dashboard"/> : 
            <Redirect to="/login" />
    )
};