import {AdminAccount} from "../../../models/AdminAccount";
import React, {useState} from "react";
import styles from './AdminAccountModal.module.css';
import {Button, Form} from "react-bootstrap";
import {AdminAccountStatus} from "../../../common/constants";
import {WebApiClient} from "../../../common/webApiClient";

export const AdminAccountModal: React.FC<{account: AdminAccount, closeModal: () => void, onChange: () => void}> = (props) => {
    const [status, setStatus] = useState(props.account.status);
    const [error, setError] = useState("");

    const submitUpdateAccount = () => {
        const client = WebApiClient();

        let data = {};
        if (status !== props.account.status) data = {Status: status, ...data};

        client.put<AdminAccount>('/api/system-accounts/' + props.account.adminAccountId, data)
            .then(response => {
                props.onChange();
                props.closeModal();
            })
            .catch(e => {
                setError(e.message);
            })
    }

    return (
        <div className={styles.container}>
            <Form onSubmit={(e) => {e.preventDefault(); submitUpdateAccount()}}>
                <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <Form.Control type={"text"} disabled value={props.account.username} />
                    <Form.Label>Email</Form.Label>
                    <Form.Control type={"text"} disabled value={props.account.email} />
                    <Form.Label>Type</Form.Label>
                    <Form.Control type={"text"} disabled value={props.account.type} />
                    <Form.Label>Status</Form.Label>
                    <Form.Control as={"select"} type={"select"} value={status} onChange={(e) => setStatus(e.target.value)}>
                        {Object.values(AdminAccountStatus).map((status, i) => (
                            <option key={i} value={status}>{status}</option>
                        ))}
                    </Form.Control>
                    <Button variant={"primary"} type={"submit"}>Update</Button>
                    {error && <div style={{color: "red"}}>{error}</div>}
                </Form.Group>
            </Form>
        </div>
    )
}