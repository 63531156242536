import React, {useEffect, useState} from "react";
import {inspect} from "util";
import styles from './ProfileView.module.css';
import {Button, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import {WebApiClient} from "../../../common/webApiClient";
import {AuthenticationResponse} from "../../../models/AuthenticationResponse";
import {getRolesFromJwt} from "../../../common/getRolesFromJwt";
import Cookie from "js-cookie";


export const ProfileView: React.FC = () => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [mismatchError, setMismatchError] = useState("");

    useEffect(() => {
        if (!newPassword2) return;
        if (newPassword1 !== newPassword2) {
            setMismatchError("Password do not match")
        } else {
            setMismatchError("");
        }
    }, [newPassword2, newPassword1])

    const handleChangePassword = async (event: any) => {
        event.preventDefault();
        const apiClient = WebApiClient();
        try {
            await apiClient.put("api/system-accounts/password", {
                "oldPassword": oldPassword,
                "newPassword": newPassword1
            });
            setSuccess("Password changed");
            setError("");
        } catch (e: any) {
            setError(e.message);
            setSuccess("");
            console.log(e);
        }
    }

    const validateForm = () => {
        if (oldPassword.length <= 0) return false;
        if (newPassword1.length <= 0) return false;
        if (newPassword2.length <= 0) return false;

        if (newPassword1 !== newPassword2) return false;

        return true;
    }

    return (
        <div className={styles.container}>
            <form onSubmit={handleChangePassword}>
                <FormGroup controlId="oldPassword">
                    <FormLabel>Old Password</FormLabel>
                    <FormControl
                        type="password"
                        value={oldPassword}
                        onChange={e => setOldPassword(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="newPassword1">
                    <FormLabel>Password</FormLabel>
                    <FormControl
                        value={newPassword1}
                        onChange={e => setNewPassword1(e.target.value)}
                        type="password"
                    />
                </FormGroup>
                {mismatchError && <div style={{color: "red"}}>{mismatchError}</div>}
                <FormGroup controlId="newPassword2">
                    <FormLabel>Re-type password</FormLabel>
                    <FormControl
                        value={newPassword2}
                        onChange={e => setNewPassword2(e.target.value)}
                        type="password"
                    />
                </FormGroup>
                <Button variant="danger" block disabled={!validateForm()} type="submit">
                    Change password
                </Button>
                {error && <div style={{color: "red"}}>{error}</div>}
                {success && <div style={{color: "green"}}>{success}</div>}
            </form>
        </div>
    )
}