import _ from "lodash";


export function createFilterQuery(filters: {[key: string]: {[key:string]: any}} | null, pageNumber?: number, pageSize?: number, sortColumn?: string, sortDescending?: boolean) {
    const filterQuery = filters && !_.isEmpty(filters) ? Object.keys(filters).map((filterName) => {
        const filterObj = filters[filterName];
        let filterValues;
        switch (filterObj.filterType) {
            case "TEXT":
                filterValues = filters[filterName].filterVal.split(',');
                break;
            case "SELECT":
                filterValues = [filters[filterName].filterVal];
                break;
            case "DATE":
                if (filters[filterName].filterVal.comparator && filters[filterName].filterVal.date) {
                    filterValues = [filters[filterName].filterVal.comparator + "," + filters[filterName].filterVal.date.toISOString()];
                    break;
                }
            default:
                filterValues = [];
        }
        return filterValues.map((val: string) => `${filterName}=${val.trim()}`).join('&');
    }).join('&') : "";

    let query = "?"

    if (pageSize && pageNumber) {
        const pageNumberQuery = `pageNumber=${pageNumber}`;
        const pageSizeQuery = `pageSize=${pageSize}`;

        query += `${pageNumberQuery}&${pageSizeQuery}&`;
    }
    console.log({sortColumn, sortDescending});
    if (sortColumn && sortDescending !== undefined) {
        const sortColumnQuery = `sortBy=${sortColumn}`;
        const sortOrderQuery = `descending=${sortDescending}`;

        query += `${sortColumnQuery}&${sortOrderQuery}&`;
    }

    if (filterQuery) {
        query += `${filterQuery}`;
    }

    if (query.length === 1) {
        return "";
    }

    if (query.endsWith('&')) {
        query = query.slice(0, -1);
    }

    return query;
}