import React, {FormEvent, useEffect, useState} from "react";
import {AccountProfileFull} from "../../../models/AccountProfile";
import styles from './AccountModal.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Form, FormGroup, Image, InputGroup} from "react-bootstrap";
import {AccountStatus, AccountTypes, LogStatus} from "../../../common/constants";
import {WebApiClient} from "../../../common/webApiClient";
import {Input, Label} from "reactstrap";
import {ClickableUserLogs} from "../../LayoutComponents/ClickableLinks/ClickableInternalLinks";
import {AccountView} from "./AccountView";
import {Topic} from "../../../models/Topic";
import {PagedResponse} from "../../../models/PagedResponse";
import {createFilterQuery} from "../../../common/createFilterQuery";

type AccountModalProps = {
    accountId: string,
    closeModal: () => void,
    onChange: (updatedAccount: AccountProfileFull) => void,
};

export const AccountModal: React.FC<AccountModalProps> = ({accountId, closeModal, onChange}) => {
    const apiClient = WebApiClient();
    const [account, setAccount] = useState(undefined as AccountProfileFull | undefined);
    const [topCreator, setTopCreator] = useState(account?.isTopCreator);
    const [excludedFromFeed, setExcludedFromFeed] = useState(account?.isExcludedFromFeed);
    const [accountType, setAccountType] = useState(account?.accountType);
    const [accountStatus, setAccountStatus] = useState(account?.accountStatus);
    const [suspendedUntil, setSuspendedUntil] = useState(account?.suspendedUntil?.toString());
    const [creatorTopicId, setCreatorTopicId] = useState(account?.creatorTopic?.topicId);

    const [topics, setTopics] = useState([] as Topic[]);

    const [sureDelete, setSureDelete] = useState(false);
    const [hasBeenRemoved, setHasBeenRemoved] = useState(false);

    const [error, setError] = useState<null | string>(null);

    React.useEffect(() => {
        let active = true;

        const populateAccount = async () => {
            try {
                const url = 'api/accounts/' + accountId;
                console.log(url);
                const response = await apiClient.get<AccountProfileFull>(url);
                if (active) {
                    setAccount(response);
                    setTopCreator(response.isTopCreator);
                    setExcludedFromFeed(response.isExcludedFromFeed);
                    setAccountType(response.accountType);
                    setAccountStatus(response.accountStatus);
                    setSuspendedUntil(response.suspendedUntil?.toLocaleString());
                    setCreatorTopicId(response.creatorTopic.topicId);
                }
            } catch(e: any) {
                console.log(e.message);
            }
        }
        populateAccount().then(r => {});
        return () => {
            active = false;
        }
    }, [accountId]);

    useEffect(() => {
        apiClient.get<PagedResponse<Topic[]>>('/api/topics?PageNumber=1&PageSize=200')
            .then(response => {
                setTopics(response.data);
            }).catch(e => {
                setError(e.message);
            })
    }, [])

    const closeModalButton = () => (
        <FontAwesomeIcon className={styles.closeButton} icon="times" onClick={closeModal} />
    );

    const updateAccount = (e: FormEvent) => {
        e.preventDefault();
        let data = {};
        data = account?.isTopCreator !== topCreator ? { isTopCreator: topCreator, ...data } : data;
        data = account?.isExcludedFromFeed !== excludedFromFeed ? { isExcludedFromFeed: excludedFromFeed, ...data } : data;
        data = account?.creatorTopic?.topicId !== creatorTopicId ? {creatorTopicId: creatorTopicId, ...data} : data;
        data = account?.accountType !== accountType ? {accountType: accountType, ...data} : data;
        data = account?.accountStatus !== accountStatus ? {accountStatus: accountStatus, ...data} : data;
        data = account?.suspendedUntil !== suspendedUntil && suspendedUntil ? {suspendedUntil: suspendedUntil, ...data} : data;
        apiClient.put<AccountProfileFull>('api/accounts/' + account?.accountId, data).then((account) => {
            onChange(account);
            closeModal();
        }).catch((e: Error) => {
            setError(e.message)
            console.log(e.message)
        });
    }

    const buySellAccountHeader = (text: string) => {
        return <div className={styles.buySellAccountHeader}>
            {text}
        </div>
    }

    const buySellAccountRow = (label: string, value: string | number) => {
        return <div className={styles.buySellAccountRow}>
            <div className={styles.buySellAccountLabel}>{label}:</div>
            <div className={styles.buySellAccountValue}>{value}</div>
        </div>
    }

    return (
        <div className={styles.container}>
            <div>
                <AccountView account={account} />
            </div>
            <div style={account?.accountConfirmed ? {color: "green"} : {color: "red"}}>
                <b>{account?.accountConfirmed ? "Confirmed" : "Not Confirmed"}</b>
            </div>
            {ClickableUserLogs("View Logs", account?.userName)}
            {account?.sellerConnectionAccount && <div className={styles.buySellAccountForm}>
                {buySellAccountHeader("Seller info")}
                {buySellAccountRow("Stripe Id", account.sellerConnectionAccount.stripeAccountId)}
                {buySellAccountRow("Created At", new Date(account.sellerConnectionAccount.createdAt).toLocaleString())}
                {buySellAccountRow("Status", account.sellerConnectionAccount.status)}
                {buySellAccountRow("Number of sold ALogs", account.sellerConnectionAccount.numberOfSoldLogs)}
                {buySellAccountRow("Sale amount", (account.sellerConnectionAccount.soldFor / 100).toFixed(2) + " usd")}
                {account.sellerConnectionAccount.updatesRequired && buySellAccountRow("Updates required", account.sellerConnectionAccount.updatesRequired)}
                {account.sellerConnectionAccount.disabledReason && buySellAccountRow("Disabled reason", account.sellerConnectionAccount.disabledReason)}
            </div>}
            {account && account.numberOfBoughtLogs > 0 && <div className={styles.buySellAccountForm}>
                {buySellAccountHeader("Buyer info")}
                {buySellAccountRow("Number of bought ALogs", account.numberOfBoughtLogs)}
                {buySellAccountRow("Buy amount", (account.boughtFor / 100).toFixed(2) + " usd")}
            </div>}
            <Form className={styles.form} onSubmit={updateAccount}>
                <FormGroup className={styles.form_group}>
                    <Label style={{display: "block", marginTop: "15px"}}>Account type</Label>
                    <Form.Control size={"sm"} as={"select"} value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                        {Object.values(AccountTypes).map((accountType, i) => (
                            <option key={i} value={accountType}>{accountType}</option>
                        ))}
                    </Form.Control>
                    {accountType == AccountTypes.Creator &&
                    <Form.Check inline label={"Top Creator"} type={"checkbox"} id={'inline-checkbox-1'} checked={topCreator} onChange={() => setTopCreator(!topCreator)}/>}
                    {accountType == AccountTypes.Creator && <div>
                        <Label style={{display: "block", marginTop: "15px"}}>Creator Topic</Label>
                        <Form.Control size={"sm"} as={"select"} value={creatorTopicId} onChange={(e) => setCreatorTopicId(e.target.value)}>
                            {Object.values(topics).map((topic, i) => (
                                <option key={i} value={topic.topicId}>{topic.name}</option>
                            ))}
                        </Form.Control>
                    </div>}
                    <Label style={{ display: "block", marginTop: "15px" }}>Exclude from Feed</Label>
                    <Form.Check inline label={"Exclude"} type={"checkbox"} id={'inline-checkbox-2'} checked={excludedFromFeed} onChange={() => setExcludedFromFeed(!excludedFromFeed)} />
                    <Label style={{display: "block", marginTop: "15px"}}>Account status</Label>
                    <Form.Control size={"sm"} as={"select"} value={accountStatus} onChange={(e) => setAccountStatus(e.target.value)}>
                        {Object.values(AccountStatus).map((accountStatus, i) => (
                            <option key={i} value={accountStatus}>{accountStatus}</option>
                        ))}
                    </Form.Control>
                    {accountStatus === AccountStatus.Suspended && <div className={styles.date_explain_container}>
                        <div>Set active after</div>
                        <Input type={"date"} value={suspendedUntil ? suspendedUntil.substr(0, 10) : suspendedUntil} onChange={(e) => setSuspendedUntil(e.target.value)}/></div>}
                    {accountStatus === AccountStatus.Closed && <div className={styles.date_explain_container}>
                        <div>Delete account after</div>
                        <Input type={"date"} value={suspendedUntil ? suspendedUntil.substr(0, 10) : suspendedUntil} onChange={(e) => setSuspendedUntil(e.target.value)}/></div>}
                    <Button variant={"primary"} type={"submit"} style={{marginTop: "15px"}}>
                        Update
                    </Button>
                </FormGroup>
            </Form>
            {error ? <div style={{color: "red"}}>{error}</div> : null}
            <Button onClick={() => removeAllPosts()} variant={"danger"}>{hasBeenRemoved ? "Done" : sureDelete ? "Are you sure?" : "Remove all posts"}</Button>
            {closeModalButton()}
        </div>
    );
    
    function removeAllPosts() {
        if (hasBeenRemoved) {
            closeModal()
        }
        if (!sureDelete) {
            setSureDelete(true);
            setTimeout(() => {
                setSureDelete(false);
            }, 2000);
        } else {
            const data = {postStatus: LogStatus.Removed}
            apiClient.put("api/posts/account/" + account?.accountId + "/status", data).then(() => {
                setHasBeenRemoved(true)
            }).catch((e: Error) => {
                setError(e.message)
                console.log(e.message)
            });
        }
    }
}
